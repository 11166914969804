import {PriorityEnum} from "@shared/enums/PriorityEnum";

const DocSignRequestStatus = Object.freeze({
	PENDING: 'PENDING',
	VIEWED: 'VIEWED',
	SIGNED: 'SIGNED',
	DECLINED: 'DECLINED',
})

let getStatusInputClass = function (status){
	switch (status){
		case DocSignRequestStatus.PENDING:
			return "status-pending"
		case PriorityEnum.HIGH:
			return "priority-high"
		case PriorityEnum.MEDIUM:
			return "priority-medium"
		case PriorityEnum.LOW:
			return "priority-low"
	}
}

let getSignStatusLabel = function (signStatus, isClient){
	let label = "Not set"
	switch (signStatus) {
		case DocSignRequestStatus.PENDING:
			if(isClient){
				label = "Awaiting my signature"
			} else {
				label = "Sent for signature"
			}
			break;
		case DocSignRequestStatus.SIGNED:
			label = "Signed"
			break;
		case DocSignRequestStatus.DECLINED:
			label = "Declined"
			break;
	}
	return label
}

let getSignStatusClass = function (signStatus){
	let label = ""
	switch (signStatus) {
		case DocSignRequestStatus.PENDING:
			label = "badge-warning"
			break;
		case DocSignRequestStatus.SIGNED:
			label = "badge-success"
			break;
		case DocSignRequestStatus.DECLINED:
			label = "badge-danger"
			break;
	}
	return label
}

export {
	DocSignRequestStatus,
	getSignStatusClass,
	getSignStatusLabel
}

