<template>
	<div :class="{'show-on-hover-parent': showDividerOnHover, 'whiter-smoke': showHighlight}"
		 v-bind="attrsStylesClass"
		 class="form-row-root" @mouseup="clearFormValidation()">
		<hr v-if="showDividerTop"/>
		<!--		<el-form-item :prop="prop" class="el-form-item form-padding">-->
		<div class="form-padding" style="line-height: 1.6;" :class="{'el-form-item': !!prop, 'title-normal': !isTitleBold}">
			<div class="el-form-item__content">


				<div class="col px-0">
					<div class="qw-row">

						<div v-if="title && isVerticalComputed" class="form-subtitle pr-3">
							{{ title }}
							<ToolTipView :analyticsProps="tooltipAnalyticsProps"
										 v-if="isVerticalComputed || isTooltipNearTitle"
										 :text="tooltipText"
										 :type="tooltipType"
										 :position="tooltipBubblePosition">
								<slot name="tooltip"></slot>
							</ToolTipView>
						</div>
						<slot name="title-postfix"></slot>
					</div>

					<div class="row mx-0 align-items-center">
						<div v-if="title && !isVerticalComputed" class="form-subtitle pr-3"
							 :style="{'min-width': labelMinWidth + 'px'}">
							{{ title }}
							<slot name="title-postfix"></slot>

							<ToolTipView :analyticsProps="tooltipAnalyticsProps"
										 v-if="isTooltipNearTitle"
										 :text="tooltipText"
										 :type="tooltipType"
										 :position="tooltipBubblePosition">
								<slot name="tooltip"></slot>
							</ToolTipView>

						</div>

						<div class="mr-1" v-if="hasPrefix">
							<slot name="prefix">{{ prefix }}</slot>
							<qw-button-edit
								v-if="showEditButtonPrefix"
								:class="{'disabled': disabled}"
								@click="!disabled && $attrs.onEdit ? $attrs.onEdit() : () => {}"/>
						</div>

						<div class="col px-0 qw-row" :class="contentClass">

							<div class="col px-0">
								<el-form-item v-if="prop" :prop="prop" :rules="rules">
									<slot :class="contentClass"></slot>
								</el-form-item>
								<slot v-else :class="contentClass"></slot>
							</div>


						</div>
						<div class="ml-1" v-if="$slots.postfix || (!showEditInPrefix && $attrs.onEdit) || (!isVerticalComputed && !isTooltipNearTitle)">

							<slot name="postfix">{{ postfix }}</slot>
							<qw-button-edit
								v-if="!showEditInPrefix && $attrs.onEdit"
								:class="{'disabled': disabled}"
								@click="disabled ? () => {} : $attrs.onEdit"/>

							<ToolTipView :analyticsProps="tooltipAnalyticsProps"
										 v-if="!isVerticalComputed && !isTooltipNearTitle"
										 :text="tooltipText"
										 :type="tooltipType"
										 :position="tooltipBubblePosition">
								<slot name="tooltip"></slot>
							</ToolTipView>

						</div>


					</div>
				</div>
			</div>
		</div>

		<!--		</el-form-item>-->
		<!--		<hr v-if="showDivider || showDividerOnHover"-->
		<!--			:class="{-->
		<!--			'show-on-hover-item': showDividerOnHover,-->
		<!--			'border-primary': isFocusedData-->
		<!--		}"/>-->
	</div>
</template>

<script>
import BaseFormRow from "@/core/components/Form/Base/BaseFormRow.vue"
import ToolTipView from "@/core/components/Form/Base/ToolTipView.vue";
import QwButtonEdit from "@/core/components/Controls/Buttons/QwButtonEdit.vue";
import {isMobileScreen} from "@/core/screenHelper";

export default {
	name: "FormRow",
	components: {QwButtonEdit, ToolTipView},
	extends: BaseFormRow,
	inheritAttrs: false,

	props: {
		showHighlight: Boolean,
		labelMinWidth: {Number, default: 0},
		isVertical: {Boolean, default: false},
		forceVerticalOnMobile: {Boolean, default: true},
		tooltipPosition: {String, default: "title"},
		isDotted: {Boolean, default: false},
		showDividerOnHover: {Boolean, default: false},
		isTitleBold: {Boolean, default: true},
		tooltipBubblePosition: {String, default: "right"},
		rules: Object,
		tooltipType: String,
		tooltipAnalyticsProps: Object,
		prefix: String,
		postfix: String,
		showEditInPrefix: Boolean,
		contentClass: [String, Array]
	},


	computed: {
		isTooltipNearTitle() {
			return this.tooltipPosition === "title"
		},

		isEditListenerProvided() {
			return !!this.$attrs.onEdit
		},
		showEditButtonPrefix() {
			return this.showEditInPrefix && this.$attrs.onEdit
		},

		hasPrefix() {
			return !!this.$slots.prefix || this.prefix || this.showEditButtonPrefix
		},

		isVerticalComputed() {
			return isMobileScreen() && this.forceVerticalOnMobile ? true : this.isVertical
		}
	}
}
</script>

<style lang="scss">
.form-row-root {
	.title-normal {
		.form-subtitle {
			font-weight: normal;
		}
	}
}

</style>
