function addCytoscapeListeners() {
	let {
		cy,
		options
	} = this
	// console.log("edgehandles")
	// grabbing nodes
	this.addListener(cy, 'drag', () => {
		// console.log("edgehandles: drag")
		this.grabbingNode = true
	})
	this.addListener(cy, 'free', () => {
		// console.log("edgehandles: free")
		this.grabbingNode = false
	})

	function isPanMode(e) {
		return e.target.selected() && cy.filter(":selected").length > 1
	}

	// show handle on hover
	this.addListener(cy, 'mouseover', 'node', e => {
		// console.log("edgehandles: mouseover")
		//if current node is selected and there are more than one selected nodes, hide the handle
		//we assume this is a multi selection pan mode
		if(!isPanMode(e) && !e.target.data().isAuxNode){
			this.show(e.target)
		}
	})

	this.addListener(cy, 'mouseout', 'node', e => {
		let position = e.position

		let targetBox = this.handleNode.boundingBox()

		// console.log("edgehandles: mouseout ", position, targetBox)
		// console.log("edgehandles: e ", e)
		if( position.x < targetBox.x1 ||
			position.x > targetBox.x2 ||
			position.y < targetBox.y1 ||
			position.y > targetBox.y2){
			this.removeHandle()
		}
	})

	// hide handle on tap handle
	this.addListener(cy, 'tap', 'node', e => {
		// console.log("edgehandles: tap node")
		let node = e.target

		if (!node.same(this.handleNode)) {
			this.show(node)
		}
	})

	// hide handle when source node moved
	this.addListener(cy, 'drag', 'node', e => {
		// console.log("edgehandles: drag node")
		if (e.target.same(this.sourceNode)) {
			this.hide(e.target)
		}
	})
	this.addListener(cy, 'dragfree', 'node', e => {
		// console.log("edgehandles: dragfree node")
		if (e.target.same(this.sourceNode) && !isPanMode(e)) {
			this.show(e.target)
		}
	})

	// start on tapstart handle
	// start on tapstart node (draw mode)
	// toggle on source node
	this.addListener(cy, 'tapstart', 'node', e => {
		// console.log("edgehandles: tapstart node")
		let node = e.target

		if (node.same(this.handleNode)) {
			this.start(this.sourceNode)
		} else if (this.drawMode) {
			this.start(node)
		} else if (node.same(this.sourceNode)) {
			// this.hide()
		}
	})

	// update line on drag
	this.addListener(cy, 'tapdrag', e => {
		// console.log("edgehandles: tapdrag")
		this.update(e.position)
	})

	// hover over preview
	this.addListener(cy, 'tapdragover', 'node', e => {
		// console.log("edgehandles: tapdragover node")
		// console.log("tapdragover: e.target = ", e.target)

		this.preview(e.target, true, e)
	})

	// hover out unpreview
	this.addListener(cy, 'tapdragout', 'node', e => {
		// console.log("edgehandles: tapdragout node")
		if (options.snap && e.target.same(this.targetNode)) {
			// then keep the preview
		} else {
			this.unpreview(e.target, e)
		}
	})

	this.addListener(cy, 'tapdragover', 'edge', e => {
		// console.log("edgehandles: tapdragover edge")
		// console.log("tapdragover: e.target = ", e.target)
		// console.log("event0 = ", e)
		let data = e.target.data() || {}
		let {lineSubType} = data
		// console.log("edgehandles: target.data() = ", e.target.data())
		if (lineSubType && !lineSubType.startsWith("family-")) {
			return
		}

		this.preview(e.target, true, e)
	})

	// hover out unpreview
	this.addListener(cy, 'tapdragout', 'edge', e => {
		// console.log("edgehandles: tapdragout edge")
		if (options.snap && e.target.same(this.targetNode)) {
			// then keep the preview
		} else {
			this.unpreview(e.target)
		}
	})

	// stop gesture on tapend
	this.addListener(cy, 'tapend', (e) => {
		console.log("edgehandles: tapend: this = ", this)

		let node = e.target

		console.log("edgehandles: tapend: node = ", node)

		if (node && node.same && node.same(this.handleNode) && this.sourceNode) {
			console.log("edgehandles: tapend")
			this.cy.nodes().unselect()
			this.sourceNode.select()
		}
		this.stop()
	})

	// hide handle if source node is removed
	this.addListener(cy, 'remove', e => {
		// console.log("edgehandles: remove")
		if (e.target.same(this.sourceNode)) {
			this.hide()
		}
	})

	return this
}

export default {
	addCytoscapeListeners
}
