/**
 * Created by DemonRay on 2019/3/25.
 */

import cytoscape from '@qwoach/cytoscape'
// import cytoscape from '../../cytoscape-genogram'
import utils from '../utils'
import EventBus from '../utils/eventbus'

import compoundDragAndDrop from 'cytoscape-compound-drag-and-drop';
import toolbar from './cyeditor-toolbar'
import snapGrid from './cyeditor-snap-grid'
import undoRedo from './cyeditor-undo-redo'
import clipboard from './cyeditor-clipboard'
import cynavigator from './cyeditor-navigator'
import edgehandles from './cyeditor-edgehandles'
import noderesize from './cyeditor-node-resize'
import editElements from './cyeditor-edit-elements'
import dragAddNodes from './cyeditor-drag-add-nodes'
import contextMenu from './cyeditor-context-menu'
import templates from './cyeditor-templates'
import {defaultConfData, defaultEditorConfig, defaultNodeTypes} from '../defaults'
import GenogramRenderer from './GenogramRenderer'
import edgeConnections from 'cytoscape-edge-connections';
import {
	findLineTypeItemByLineType,
	straightLine
} from "@/core/recoveryCoachingApp/coach/clients/ClientDetails/Genogram/GenogramEditor/lib/cyeditor-edit-elements/LineTypeItem";
import KeyActionListener from "@/core/js/helpers/KeyActionListener";
import {asArray, delay, uuidv4} from "@/utils/helpers";
import {
	getData
} from "@/core/recoveryCoachingApp/coach/clients/ClientDetails/Genogram/GenogramEditor/lib/genogramUtils";

const NO_LISTENER = undefined

// GenogramRenderer.prototype.register(cytoscape)


class CyEditor extends EventBus {

	constructor(params = defaultEditorConfig, analyticsManager) {
		super()
		this._plugins = {}
		this._listeners = {}
		this.analyticsManager = analyticsManager
		this._init(params)
	}

	_init(params) {
		this._initOptions(params)
		this._registerExtensions()
		this._initDom()
		this._initCy()
		this._initPlugin()
		this._initEvents()

		setTimeout(() => {
			this.invalidateLabelFontSize()
			this.syncLeftPanelNodesByLimit()
		}, 0)
	}

	_registerExtensions(){
		let readOnly = this.cyOptions.readonly
		let rendererOptions = this.cyOptions.renderer
		if(!readOnly){
			cytoscape.use(edgehandles)
		}
		if(this.editorOptions.compoundDnd.isEnabled){
			cytoscape.use(compoundDragAndDrop)
		}
		cytoscape.use(dragAddNodes)
		cytoscape.use(edgeConnections);
		cytoscape.use(undoRedo)
		cytoscape.use(contextMenu)
		cytoscape.use(cynavigator)
		cytoscape.use(snapGrid)
cytoscape.use(noderesize)
// cytoscape.use(editElements)
		cytoscape.use(toolbar)
		cytoscape.use(clipboard)
		cytoscape.use(templates)

		cytoscape('renderer', rendererOptions.name, rendererOptions.clazz)

	}

	setupCdnd(){

		let compoundDnd = this.editorOptions.compoundDnd || {}

		if(!compoundDnd.isEnabled){
			return;
		}
		var cdnd = this.cy.compoundDragAndDrop({
			newParentNode: (grabbedNode, dropSibling) => ({
				group: 'nodes',
				data: {
					type: 'compound',
					id: uuidv4()
				},
			}),
		});

		var removeEmptyParents = true;

		var isParentOfOneChild = (node) => {
			let res = node.isParent() && node.children().length === 1;
			// console.log("compoundDnd: isParentOfOneChild: node = ", node, ", res = ", res)
			return res
		};

		var removeParent = (parent) => {
			parent.children().move({ parent: null });
			console.log("compoundDnd: removeParent: parent = ", parent, ", isParent = ", parent.isParent(), ", isParentOfOneChild = ", isParentOfOneChild(parent))
			parent.children().data({ parent: null });
			parent.remove();
		};

		var removeParentsOfOneChild = () => {
			this.cy.nodes().filter(node => isParentOfOneChild(node)).forEach(node => removeParent(node));
		};

		// custom handler to remove parents with only 1 child on drop
		this.cy.on('cdndout', (event, dropTarget) => {
			if( removeEmptyParents && isParentOfOneChild(dropTarget) ){
				removeParent(dropTarget);
			}
		});

		// custom handler to remove parents with only 1 child (on remove of drop target or drop sibling)
		this.cy.on('remove', (event) => {
			if( removeEmptyParents ){
				removeParentsOfOneChild();
			}
		});
	}

	_verifyParams(params) {
		const mustBe = (arr, type) => {
			let valid = true
			arr.forEach(item => {
				const typeItem = typeof params[item]
				if (typeItem !== type) {
					console.warn(`'editor.${item}' must be ${type}`)
					valid = false
				}
			})
			return valid
		}
		const {
			zoomRate,
			toolbar,
			nodeTypes
		} = params
		mustBe(['noderesize', 'dragAddNodes', 'elementsInfo', 'snapGrid', 'navigator', 'useDefaultNodeTypes', 'autoSave'], 'boolean')
		mustBe(['beforeAdd', 'afterAdd'], 'function')

		if (zoomRate <= 0 || zoomRate >= 1 || typeof zoomRate !== 'number') {
			console.warn(`'editor.zoomRate' must be < 1 and > 0`)
		}

		if (typeof toolbar !== 'boolean' && !Array.isArray(toolbar)) {
			console.warn(`'editor.nodeTypes' must be boolean or array`)
		}

		if (!Array.isArray(nodeTypes)) {
			console.warn(`'editor.nodeTypes' must be array`)
		}
	}

	_initOptions(params = {}) {
		this.editorOptions = Object.assign({}, defaultEditorConfig.editor, params.editor)
		let compoundDnd = this.editorOptions.compoundDnd || {}

		this.editorOptions.compoundDnd = {
			...defaultEditorConfig.editor.compoundDnd,
			...compoundDnd
		}

		this._verifyParams(this.editorOptions)
		const {useDefaultNodeTypes, zoomRate} = this.editorOptions
		this._handleOptonsChange = {
			snapGrid: this._snapGridChange,
			lineType: this._lineTypeChange
		}
		if (params.editor && params.editor.nodeTypes && useDefaultNodeTypes) {
			this.setOption('nodeTypes', defaultNodeTypes.concat(params.editor.nodeTypes))
		}
		if (zoomRate <= 0 || zoomRate >= 1) {
			console.error('zoomRate must be float number, greater than 0 and less than 1')
		}
		this.cyOptions = Object.assign({}, defaultEditorConfig.cy, params.cy)

		const {elements} = this.cyOptions
		if (elements) {
			if (Array.isArray(elements.nodes)) {
				elements.nodes.forEach(node => {
					node.data = Object.assign({}, defaultConfData.node, node.data)
				})
			}
			if (Array.isArray(elements.edges)) {
				elements.edges.forEach(edge => {
					edge.data = Object.assign({}, defaultConfData.edge, edge.data)
				})
			}
		}
	}

	_initCy() {
		console.log("this.cyOptions: ", JSON.parse(JSON.stringify(this.cyOptions)))
		this.cyOptions.container = this.cyOptions.container || '#cy'
		console.log("this.cyOptions1: ", JSON.parse(JSON.stringify(this.cyOptions)))
		if (typeof this.cyOptions.container === 'string') {
			console.log("this.cyOptions2: ", this.cyOptions.container)
			this.cyOptions.container = utils.query(this.cyOptions.container)[0]
		}
		console.log("this.cyOptions3: ", JSON.parse(JSON.stringify(this.cyOptions)), ", this.cyOptions.container = ", this.cyOptions.container)
		if (!this.cyOptions.container) {
			console.error('There is no any element matching your container')
			return
		}
		this.cyOptions.renderer = {
			name: "genogramRenderer",
		},

		this.cy = cytoscape(this.cyOptions)

	}

	_initDom() {
		// let {dragAddNodes, navigator, elementsInfo, toolbar, container} = this.editorOptions
		// let left = dragAddNodes ? `<div class="left"></div>` : ''
		// let navigatorDom = navigator ? `<div class="panel-title">导航器</div><div id="thumb"></div>` : ''
		// let infoDom = elementsInfo ? `<div id="info"></div>` : ''
		// let domHtml = toolbar ? '<div id="toolbar"></div>' : 'hey'
		// let right = ''
		// if (navigator || elementsInfo) {
		// 	right = `<div class="right">
        //         ${navigatorDom}
        //         ${infoDom}
        //       </div>`
		// }
		// domHtml += `<div id="editor">
        //         ${left}
        //         <div id="cy"></div>
        //         ${right}
        //       </div>`
		// let editorContianer
		// if (container) {
		// 	if (typeof container === 'string') {
		// 		editorContianer = utils.query(container)[0]
		// 	} else if (utils.isNode(container)) {
		// 		editorContianer = container
		// 	}
		// 	if (!editorContianer) {
		// 		console.error('There is no any element matching your container')
		// 		return
		// 	}
		// } else {
		// 	editorContianer = document.createElement('div')
		// 	editorContianer.className = 'cy-editor-container'
		// 	document.body.appendChild(editorContianer)
		// }
		// editorContianer.innerHTML = domHtml
	}

	_initEvents() {
		const {editElements, edgehandles, noderesize, undoRedo} = this._plugins

		this._listeners.showElementInfo = () => {
			if (editElements) {
				editElements.showElementsInfo()
			}
		}

		this._listeners.handleCommand = this._handleCommand.bind(this)

		this.keyActionListener = new KeyActionListener((command, event) => this._listeners.handleCommand(event, {command}))


		this._listeners.hoverout = (e) => {
			if (edgehandles) {
				edgehandles.active = true
				edgehandles.stop(e)
			}
			if (noderesize) {
				noderesize.clearDraws()
			}
		}

		this._listeners.select = (e) => {
			if (this._doAction === 'select') return
			// if (undoRedo) {
			// 	this._undoRedoAction('select', e.target)
			// }
		}
		this._listeners.unselect = (e) => {
			// if (this._doAction === 'select') return
			// if (undoRedo) {
			// 	this._undoRedoAction('unselect', e.target)
			// }
		}

		this._listeners.addNodeStart = (evt, el) => {

			if(window.screen.width < 768){
				// this.toggleLeftPanel()
			}

		}

		this._listeners.addEles = async (evt, el) => {
			console.log("addEles22: ", el)
			if (el.position) {
				let panZoom = {pan: this.cy.pan(), zoom: this.cy.zoom()}
				let x = (el.position.x - panZoom.pan.x) / panZoom.zoom
				let y = (el.position.y - panZoom.pan.y) / panZoom.zoom
				el.position = {x, y}
			}
			console.log("addEles22: el.data.type = ", el.data.type)
			if(this._plugins.templates && el.data.type && el.data.type.startsWith("template")){
				el = await this._plugins.templates.fromTemplateToElements(el)
			}

			this.addElements(el)

		}



		this.cy.on('zoom', (event) => {
			// console.log("cyzoom: this.cy.zoom() = ", this.cy.zoom())
			if(this._plugins.edgehandles){
				if(this.cy.zoom() > 0.15){
					this._plugins.edgehandles.enable()
				} else {
					this._plugins.edgehandles.disable()
				}
			}
			this.invalidateLabelFontSize()
		})

		this.cy.on("cyeditor.data", e => {
			this.invalidateLabelFontSize()
		})


		this.cy.on('nodeKeyChanged', (event, {ele, nodeKey, callback}) =>{
			let {tags, isPassedAway} = ele.data()
			nodeKey = "" + nodeKey
			if(tags){
				let maxTagsToCalculate = 3
				nodeKey = nodeKey + '_' + Math.min(tags.length, maxTagsToCalculate) + "_" + tags.filter((item, index) => index < maxTagsToCalculate).map(tag => tag.color).join('_')
			}
			if(isPassedAway){
				nodeKey += '_pa'
			}

			// console.log("nodeKeyChanged 2.2: ", nodeKey, ", tags = ", tags)
			callback(nodeKey)
		})

		this.cy.on('cyeditor.added cyeditor.remove cyeditor.data cyeditor.paste cyeditor.linetype afterDragfree cyeditor.undo cyeditor.redo', e => {
			console.log("genogram changed: on change should trigger: ", e)
			this.emit('change', e.target, this)
		})

		this.cy.on('boxselect', e => {
			console.log("genogram changed: boxselected: ", e)
			//deselect boxselect so use can pan after the first selection
			let command = "boxselect"
			let commandOpt = this._plugins.toolbar._options.commands.find(it => it.command === command)
			console.log("genogram changed: boxselected: commandOpt = ", commandOpt)
			if(commandOpt.selected){
				this._plugins.toolbar.rerender(command, { selected: false })
				console.log("genogram changed: boxselected2: commandOpt = ", commandOpt)
				this.cy.trigger('cyeditor.toolbar-command', commandOpt)
			}
		})



		this._listeners._changeUndoRedo = this._changeUndoRedo.bind(this)

		this.cy.on('cyeditor.noderesize-resized cyeditor.noderesize-resizing', this._listeners.showElementInfo)
			.on('cyeditor.toolbar-command', this._listeners.handleCommand)
			// .on('click', this._listeners.hoverout)
			.on('select', this._listeners.select)
			// .on('unselect', this._listeners.unselect)
			.on('cyeditor.addnode', this._listeners.addEles)
			.on('cyeditor.afterDo cyeditor.afterRedo cyeditor.afterUndo', this._listeners._changeUndoRedo)
			.on('cyeditor.addnodestart', this._listeners.addNodeStart)


		this.cy.on('ehcomplete', (event, sourceNode, targetNode, addedEdge) => {
			let selected = this.cy.$(':selected')
			selected.every(item => item.unselect())
			console.log("addedEdge: ", addedEdge)
			if (addedEdge && addedEdge.length > 0) {
				addedEdge[0].select()
			}
			this.cy.trigger('cyeditor.added')
		});


		this.cy.on('add grab drag dragfree', 'node', async e => {
			let node = e.target
			// console.log("drag dragfree node = ", node)
			// console.log("dragNode: e = ", e)

			if (node.data().isAuxNode) {
				await this.handleAuxNodeDrag(e, node)
				return
			}

			this.auxNodeForceFollowChild(e, node)


			this.trySyncAuxNodesForParentEdge(e, node)

			if(e.type === "dragfree"){
				this.cy.trigger('afterDragfree')
			}
		})


		this.cy.on('cyeditor.added', e => {
			console.log("add node", e)
			this.syncLeftPanelNodesByLimit()
		})


		this.cy.on('remove', 'node', e => {
			console.log("remove node", e)
			let node = e.target
			console.log("remove node: removedItems = ", node, ", data = ", node.data())
			if(node){
				console.log("remove node: hasCoreSelf0")
				let targetData = node.data()
				if (targetData && targetData.isAuxNode) {
					this.onAuxNodeRemoved(e.target)
				}
				this.syncLeftPanelNodesByLimit()
			}
		})

		this.cy.on('remove', 'edge', e => {
			console.log("remove edge", e)
			this.onEdgeRemoved(e.target)
		})

		this.cy.on('afterLineTypeChange', (e, targetsParam = []) => {
			let targets = [...targetsParam]
			if(e.target.length){
				targets.push(e.target)
			}
			console.log("targets = ", targets)
			for(let target of targets){
				if(!target.isEdge() || target.data().lineSubType.startsWith("family-")){
					return
				}
				this.removeAuxNodesForParentEdge(target)

			}
		})

		this.emit('ready')
	}

	async addKids(initialNode){
		if(this._plugins.templates){
			let res = await this._plugins.templates.addKids(initialNode)
			if(!res){
				return
			}
			let {newElements, partners, marriageEdge } = res
			this.addElements(newElements)
			this.selectAllKidsAndCouple(partners, marriageEdge)
		}

	}

	async addParents(initialNode){
		if(this._plugins.templates){
			let elems = await this._plugins.templates.addParents(initialNode)
			if(!elems){
				return
			}
			this.addElements(elems)
			initialNode.select()
		}
	}

	async addSiblings(initialNode){
		if(this._plugins.templates){
			let res = await this._plugins.templates.addSiblings(initialNode)
			if(!res){
				return
			}
			let {newElements, partners, marriageEdge } = res
			this.addElements(newElements)
			this.selectAllKidsAndCouple(partners, marriageEdge)
			initialNode.select()
		}
	}

	selectAllKidsAndCouple(partners, marriageEdge){


		let auxNodes = getData(marriageEdge).auxNodes || []
		console.log("selectAllKidsAndCouple: auxNodes = ", auxNodes, ", marriageEdge = ", marriageEdge)

		for(let auxNodeId of auxNodes){
			let kidsArray = this.cy.nodes().filter(node => {
				let {auxNodes} = node.data() || {}
				// console.log("selectAllKidsAndCouple: node = ", node, ", auxNodes = ", auxNodes)

				return Array.isArray(auxNodes) && auxNodes.includes(auxNodeId)
			})
			// console.log("selectAllKidsAndCouple: kidsArray = ", kidsArray)
			// allKids.push(...kidsArray)
			kidsArray.select()
		}


		let partnersSelector = partners.map(partner => `[id="${getData(partner).id}"]`).join(", ")
		partners = this.cy.filter(partnersSelector)

		console.log("selectAllKidsAndCouple: partners = ", partners)

		partners.select()
	}

	addElements(el) {
		if(!el){
			return
		}
		console.log("addElements: el = ", el)
		el.firstTime = true
		if (!this._hook('beforeAdd', el, true)) return
		if (undoRedo) {
			this._undoRedoAction('add', el)
		} else {
			this.cy.add(el)
		}
		this.unselectAll()
		// this.cy.getElementById(el.data.id).select()
		let ids = asArray(el).map(it => it.data.id)
		let selector = ids.map(id => `[id="${id}"]`).join(", ")
		this.cy.filter(selector).select()
		this._hook('afterAdd', el)
		this.cy.trigger('cyeditor.added')
		this.invalidateLabelFontSize()
	}

	trySyncAuxNodesForParentEdge(e, node){
		let connectedEdges = node.connectedEdges()

		for(let edge of connectedEdges){
			let {lineSubType} = edge.data()
			if (lineSubType && lineSubType.startsWith("family-")) {

				let auxNodes = edge.data().auxNodes || []
				// console.log("position edge auxNodeIds = ", auxNodeIds)
				for (let auxNodeId of auxNodes) {
					let auxNode = this.cy.getElementById(auxNodeId)
					if(auxNode && auxNode.length > 0){
						this.forcePositionAuxNode(auxNode)
					}
				}
				// this.auxNodeForceFollowParentEdge(e, edge)
			}
		}
	}

	async handleAuxNodeDrag(e, node){
		if(e.type === "add"){
			let edge = this.cy.getElementById(node.data().parentEdgeId)
			if(!edge.segmentPoints()){
				await delay(0)//Workaround. edge.segmentPoints() is not available immediately when the full genogram is added for the first time.
			}
		}
		if(e.type === "dragfree"){
			this._plugins.cySnapToGrid.snapNode(node)
		}
		this.auxNodeForceSnapX(node)

	}

	auxNodeForceFollowChild(e, node){
		let auxNodes = node.neighborhood().nodes().filter(node => node.data().isAuxNode)
		if(auxNodes.length === 0){
			return
		}


		auxNodes.forEach(nodeLocal => {
			this.forcePositionAuxNode(nodeLocal)
		})

		// switch (e.type){
		// 	case "grab":
		// 		console.log("auxNodeForceFollowChild: grab, auxNodes = ", auxNodes)
		// 		allNodes.forEach(nodeLocal => nodeLocal.scratch({
		// 			grabNodePosition: Object.assign({}, nodeLocal.position()),
		// 		}))
		// 		break
		// 	case "drag": {
		// 		// console.log("auxNodeForceFollowChild: drag, node.scratch() = ", node.scratch(), ", e.position = ", e.position, ", e = ", e)
		// 		let xDiff = node.position().x - node.scratch().grabNodePosition.x
		// 		let yDiff = node.position().y - node.scratch().grabNodePosition.y
		// 		auxNodes.forEach(nodeLocal => {
		// 			let scratch = nodeLocal.scratch()
		// 			if (scratch && scratch.grabNodePosition) {
		// 				let targetX
		// 				let neighbourNodes = nodeLocal.neighborhood().nodes();
		// 				if(neighbourNodes.length > 1) {
		// 					let averageX = neighbourNodes.reduce((acc, node) => acc + node.position().x, 0) / neighbourNodes.length
		// 					// targetX = scratch.grabNodePosition.x + xDiff
		// 					targetX = averageX
		// 					nodeLocal.scratch({snapToGrid: false})
		// 				} else {
		// 					targetX = node.position().x
		// 					nodeLocal.scratch({snapToGrid: true})
		// 				}
		// 				this.auxNodeForceSnapX(nodeLocal, targetX)
		// 			}
		// 		})
		// 	}
		// 		break
		// 	case "dragfree":
		// 		// console.log("auxNodeForceFollowChild: dragfree")
		// 		allNodes.forEach(node => {
		// 			let {snapToGrid} = node.scratch()
		// 			if(snapToGrid !== false){
		// 				this._plugins.cySnapToGrid.snapNode(node)
		// 			}
		// 			node.scratch({
		// 				grabNodePosition: null,
		// 				grabPointerPosition: null,
		// 				snapToGrid: null,
		// 			})
		// 		})
		// 		break
		// }
		// console.log("dragNodeAux: node = ", node, ", scratch.grabNodePosition = ", node.scratch().grabNodePosition)

	}

	forcePositionAuxNode(auxNode){
		let targetX
		let neighbourNodes = auxNode.neighborhood().nodes();
		if(neighbourNodes.length > 1) {
			let averageX = neighbourNodes.reduce((acc, node) => acc + node.position().x, 0) / neighbourNodes.length
			targetX = averageX
			auxNode.scratch({snapToGrid: false})
		} else if (neighbourNodes.length === 1){
			targetX = neighbourNodes[0].position().x
			auxNode.scratch({snapToGrid: true})
		}
		this.auxNodeForceSnapX(auxNode, targetX)
	}

	auxNodeForceFollowParentEdge(e, edge){
		if(!edge.segmentPoints()){
			console.warn("auxNodeForceFollowParentEdge: edge.segmentPoints() is not available")
			return;
		}
		let [pt1, pt2] = edge.segmentPoints()
		let x1 = pt1.x
		let y1 = pt1.y
		let x2 = pt2.x
		let y2 = pt2.y
		let auxNodes = edge.data().auxNodes || []
		// console.log("position edge auxNodeIds = ", auxNodeIds)
		for (let auxNodeId of auxNodes) {
			let auxNode = this.cy.getElementById(auxNodeId)
			// console.log("auxNode = ", auxNode)
			if(auxNode.length === 0){
				return;
			}

			let {x, y} = auxNode.position()

			if(x < x1 || x > x2){
				x = Math.min(x2, Math.max(x1, x))

			}
			auxNode.position({x, y: y1})

			if(e.type === "dragfree") {
				this._plugins.cySnapToGrid.snapNode(auxNode)
			}

		}
	}

	auxNodeForceSnapX(node, targetX){
		let edge = this.cy.getElementById(node.data().parentEdgeId)
		if(edge.length === 0){
			this.cy.remove(node)
			return;
		}

		if(!edge.segmentPoints()){
			return;
		}
		let [pt1, pt2] = edge.segmentPoints()
		let x1 = pt1.x
		let y1 = pt1.y
		let x2 = pt2.x
		let snappedX = targetX || node.position().x
		snappedX = Math.min(x2, Math.max(x1, snappedX))
		let xPositionPercent = (snappedX - x1) / (x2 - x1)
		let newPosition = {
			x: snappedX,
			y: y1
		}
		node.position(newPosition)
	}

	syncLeftPanelNodesByLimit(){
		if(!this._plugins.dragAddNodes) {
			return
		}

		let nodesWithLimit = this.cy.nodes().filter(x => x.data().instanceLimit !== undefined)
		let typeToCount = {}
		nodesWithLimit.forEach(node => {
			let type = node.data().type
			if(!typeToCount[type]){
				typeToCount[type] = 0
			}
			typeToCount[type]++
		})

		let nodeTypes = this._plugins.dragAddNodes._options.nodeTypes
		for(let nodeType of nodeTypes){
			let {type, instanceLimit, data} = nodeType
			instanceLimit = instanceLimit || (data || {}).instanceLimit
			if(instanceLimit === undefined){
				continue
			}
			let count = typeToCount[type] || 0
			nodeType.isExcluded = count >= instanceLimit
			let nodeElemArray = this._plugins.dragAddNodes._shapePanel.querySelectorAll(`[data-type='${type}']`)
			for(let nodeElem of nodeElemArray){
				//set display style to none or visible
				nodeElem.style.display = nodeType.isExcluded ? 'none' : 'block'
			}
		}
	}

	removeAuxNodesForParentEdge(edge){
		let auxNodes = edge.data().auxNodes || []

		console.log("removeAuxNodesForParentEdge: auxNodes = ", auxNodes)

		auxNodes.forEach(nodeId =>{
			let auxNode = this.cy.getElementById(nodeId)
			this.cy.remove(auxNode)
		})
	}
	onEdgeRemoved(edge){
		if(!edge) {
			return
		}
		let targetData = edge.data()
		console.log("onEdgeRemoved: ", targetData)
		console.log("this.cy._private.map: ", this.cy._private.map)
		if(targetData){

			let nodes = [
				this.cy.getElementById(targetData.source),
				this.cy.getElementById(targetData.target),
			]
			console.log("onEdgeRemoved: nodes = ", nodes)
			this.removeAuxNodeIfNoEdges(nodes)
			this.removeAuxNodesForParentEdge(edge)
		}
	}

	unselectAll(){
		this.cy.$(':selected').unselect()
	}
	selectAll(){
		this.cy.$().select()
	}

	_initPlugin() {
		const {
			templates, dragAddNodes, elementsInfo, toolbar,
			contextMenu, snapGrid, navigator, noderesize,
			hideSaveButton
		} = this.editorOptions
		// edge
		this.setupCdnd()
		if(this.cy.edgehandles){
			this._plugins.edgehandles = this.cy.edgehandles({
				snap: false,
				handlePosition() {
					return 'middle middle'
				},
				handleNodes: (node) => {
					console.log("edgeHandleEnabled: ", node)
					return !node.data().isAuxNode
				},
				edgeParams: this._edgeParams.bind(this),
			})
		}


		// drag node add to cy
		if (dragAddNodes) {
			this._plugins.dragAddNodes = this.cy.dragAddNodes({
				readonly: this.cyOptions.readonly,
				container: this.cyOptions.containerSelectorLeft || '.left',
				nodeTypes: this.editorOptions.nodeTypes
			})
		}

		if(templates){
			this._plugins.templates = this.cy.templates({
				nodeTypes: this.editorOptions.nodeTypes,
				notifyAction: this.notifyAction.bind(this),
			})
		}

		// edit panel
		if (elementsInfo) {
			this._plugins.editElements = this.cy.editElements({
				container: '#info'
			})
		}

		// toolbar
		console.log("toolbar = ", toolbar, ", hideSaveButton = ", hideSaveButton)
		if (Array.isArray(toolbar) || toolbar === true) {
			this._plugins.toolbar = this.cy.toolbar({
				container: this.cyOptions.containerSelectorToolbar || '.cy-toolbar',
				toolbar: toolbar
			})
			if (toolbar === true || toolbar.indexOf('gridon') > -1) {
				this.setOption('snapGrid', true)
			}
			if(hideSaveButton){
				this._plugins.toolbar.removeCommand("save")
			}

		}

		let needUndoRedo = toolbar === true
		let needClipboard = toolbar === true
		if (Array.isArray(toolbar)) {
			needUndoRedo = toolbar.indexOf('undo') > -1 ||
				toolbar.indexOf('redo') > -1
			needClipboard = toolbar.indexOf('copy') > -1 ||
				toolbar.indexOf('paset') > -1
		}

		// clipboard
		if (needClipboard) {
			this._plugins.clipboard = this.cy.clipboard()
		}
		// undo-redo
		if (needUndoRedo) {
			this._plugins.undoRedo = this.cy.undoRedo()
			this.undoRedoRegisterCustomActions(this._plugins.undoRedo)
		}

		// snap-grid
		if (snapGrid) {
			this._plugins.cySnapToGrid = this.cy.snapToGrid({
				// gridSpacing: 200
			})
		}

		// navigator
		if (navigator) {
			this.cy.navigator({
				container: '#thumb'
			})
		}

		// noderesize
		if (noderesize) {
			this._plugins.noderesize = this.cy.noderesize({
				selector: 'node[resize]'
			})
		}

		// context-menu
		if (contextMenu) {
			this._plugins.contextMenu = this.cy.contextMenu()
		}
	}

	undoRedoRegisterCustomActions(undoRedo){
		this.undoRedoRegisterDataChangeAction(undoRedo)
	}

	undoRedoRegisterDataChangeAction(undoRedo){
		console.log("undoRedoRegisterDataChangeAction")
		let action = (args) => {
			let {element, data} = args
			console.log("undoRedo dataChange redo: ", args)
			let prevData = Object.assign({}, element.data())
			element.data(data)
			return {element, data: prevData}
		}

		let undo = (args) => {
			let {element, data} = args
			console.log("undoRedo dataChange undo: ", args)
			let nextData = Object.assign({}, element.data())
			element.data(data)
			return {element, data: nextData}
		}
		undoRedo.action("dataChange", action, undo)
	}

	invalidateLabelFontSize(){
		// let step = 5
		// var dim = Math.floor(12/this.cy.zoom() / step) * step
		// var maxDim = Math.min(30, Math.max(dim,20))
		let maxDim = 20//we no longer chnage font size based on zoom because this messes up layout for printing.
		console.log('zoom changed = ', this.cy.zoom(), maxDim)
		this.cy.nodes().css('font-size', maxDim);
	}

	setData(element, data){
		console.log("setData: element = ", element, ", data = ", data)
		if(this._plugins.undoRedo){
			this._undoRedoAction("dataChange", {element, data})
		} else {
			element.data(data)
		}
	}

	removeAuxNodeIfNoEdges(nodes){
		for(let node of nodes){
			console.log("removeAuxNodeIfNoEdges: ", node)
			if(node.length === 0){
				continue;
			}

			if(node.data().isAuxNode && node.connectedEdges().length === 0){
				this.cy.remove(node)
				console.log("removeAuxNodeIfNoEdges is aux: ", node)
			}
		}
	}


	onAuxNodeRemoved(auxNode){
		console.log("onAuxNodeRemoved: ", auxNode)
		let {id, parentEdgeId, childNodeId} = auxNode.data()
		let parentEdge = this.cy.getElementById(parentEdgeId)
		let childNode = this.cy.getElementById(childNodeId)
		this.cleanupAuxEdgeData(id, parentEdge)
		this.cleanupAuxChildNodeData(id, childNode)

	}

	cleanupAuxEdgeData(auxNodeId, parentEdge){
		if(parentEdge.length === 0){
			return
		}
		let {auxNodes} = parentEdge.data()
		console.log("onAuxNodeRemoved: parentEdge = ", parentEdge)
		if(auxNodes){
			let index = auxNodes.findIndex(nodeId => nodeId === auxNodeId)
			if(index >= 0){
				auxNodes.splice(index, 1)
				parentEdge.data({auxNodes})
			}
		}
	}

	cleanupAuxChildNodeData(auxNodeId, childNode){
		if(childNode.length === 0){
			return
		}
		let {auxNodes} = childNode.data()
		if(auxNodes){
			let index = auxNodes.findIndex(nodeId => nodeId === auxNodeId)
			if(index >= 0){
				auxNodes.splice(index, 1)
				childNode.data({auxNodes})
			}
		}
	}

	_snapGridChange() {
		if (!this._plugins.cySnapToGrid) return
		if (this.editorOptions.snapGrid) {
			this._plugins.cySnapToGrid.gridOn()
			this._plugins.cySnapToGrid.snapOn()
		} else {
			this._plugins.cySnapToGrid.gridOff()
			this._plugins.cySnapToGrid.snapOff()
		}
	}

	_edgeParams() {
		let value = findLineTypeItemByLineType(this.editorOptions.lineType) || straightLine
		console.log("_edgeParams: ", value, this.editorOptions.lineType)
		return {
			data: this.edgeData(value)
		}
	}

	edgeData(lineTypeItem = {}) {

		let data = {
			lineType: "bezier",
			title: lineTypeItem.title,
			lineSubType: lineTypeItem.lineType,
			// lineStyle: lineTypeItem.lineStyle
		}

		if(lineTypeItem.lineType.startsWith('family-')) {
			return {
				...data,
				lineType: "segments"
			}
		}
		return data
	}

	_lineTypeChange(value) {
		console.log("_lineTypeChange: ", value)
		let selected = this.cy.$('edge:selected')
		// if (selected.length < 1) {
		//   selected = this.cy.$('edge')
		// }
		selected.forEach(item => {
			// let lineTypePrev = item.pstyle('curve-style').value || null
			this.setData(item, this.edgeData(value))
		})
		if(selected.length){
			this.cy.trigger('afterLineTypeChange', selected)
		}
	}

	_handleCommand(evt, item) {
		console.log("_handleCommand: ", item)


		if(item.lineType){
			return this.setOption('lineType', item)
		}
		switch (item.command) {
			case 'undo' :
				this.undo()
				break
			case 'redo' :
				this.redo()
				break
			case 'gridon' :
				this.toggleGrid()
				break
			case 'zoomin' :
				this.zoom(1)
				break
			case 'zoomout' :
				this.zoom(-1)
				break
			case 'levelup' :
				this.changeLevel(1)
				break
			case 'leveldown' :
				this.changeLevel(-1)
				break
			case 'copy' :
				this.copy()
				break
			case 'paste' :
				this.paste()
				break
			case 'fit' :
				this.fit()
				break
			case 'save' :
				this.save()
				break
			case 'delete' :
				this.deleteSelected()
				break
			case 'selectall' :
				this.selectAll()
				break

			case 'toggleLeftPanel':
				this.toggleLeftPanel()
				break

			case 'boxselect':
				this.cy.userPanningEnabled(!item.selected)
				this.cy.boxSelectionEnabled(item.selected)
				this.cy.userZoomingEnabled(true)
				this.cy.zoomingEnabled(true)
				break
			default:
				break
		}
		this.analyticsManager.trackEvent(item.title, {command: item.command, context: "Genogram Toolbar clicked", selected: item.selected})

	}

	_changeUndoRedo() {
		if (!this._plugins.undoRedo || !this._plugins.toolbar) return
		let canRedo = this._plugins.undoRedo.isRedoStackEmpty()
		let canUndo = this._plugins.undoRedo.isUndoStackEmpty()
		if (canRedo !== this.lastCanRedo || canUndo !== this.lastCanUndo) {
			this._plugins.toolbar.rerender('undo', {disabled: canUndo})
			this._plugins.toolbar.rerender('redo', {disabled: canRedo})
		}
		this.lastCanRedo = canRedo
		this.lastCanUndo = canUndo
	}

	_undoRedoAction(cmd, options) {
		console.log("undoRedo: do cmd = ", cmd, options)
		this._doAction = cmd
		this._plugins.undoRedo.do(cmd, options)
	}

	_hook(hook, params, result = false) {
		if (typeof this.editorOptions[hook] === 'function') {
			const res = this.editorOptions[hook](params)
			return result ? res : true
		}
	}

	/**
	 * change editor option, support snapGrid, lineType
	 * @param {string|object} key
	 * @param {*} value
	 */
	setOption(key, value) {
		if (typeof key === 'string') {
			this.editorOptions[key] = value
			if (typeof this._handleOptonsChange[key] === 'function') {
				this._handleOptonsChange[key].call(this, value)
			}
		} else if (typeof key === 'object') {
			Object.assign(this.editorOptions, key)
		}
		if(key === "lineType"){
			this.cy.trigger('cyeditor.linetype')
		}
	}

	undo() {
		if (this._plugins.undoRedo) {
			let stack = this._plugins.undoRedo.getRedoStack()
			console.log("undoRedo: undo stack = ", stack)
			if (stack.length) {
				this._doAction = stack[stack.length - 1].action
			}
			let res = this._plugins.undoRedo.undo()
			if(res && res.move){
				this.cy.nodes().forEach(node => node.trigger("dragfree"))
			}
			this.cy.trigger('cyeditor.undo')
		} else {
			console.warn('Can not `undo`, please check the initialize option `editor.toolbar`')
		}
	}

	redo() {
		if (this._plugins.undoRedo) {
			let stack = this._plugins.undoRedo.getUndoStack()
			console.log("undoRedo: redo stack = ", stack)

			if (stack.length) {
				this._doAction = stack[stack.length - 1].action
			}
			let res = this._plugins.undoRedo.redo()
			if(res && res.move){
				this.cy.nodes().forEach(node => node.trigger("dragfree"))
			}
			this.cy.trigger('cyeditor.redo')
		} else {
			console.warn('Can not `redo`, please check the initialize option `editor.toolbar`')
		}
	}

	copy() {
		if (this._plugins.clipboard) {
			let selected = this.cy.$(':selected')
			if (selected.length) {
				this._cpids = this._plugins.clipboard.copy(selected)
				if (this._cpids && this._plugins.toolbar) {
					this._plugins.toolbar.rerender('paste', {disabled: false})
				}
			}
		} else {
			console.warn('Can not `copy`, please check the initialize option `editor.toolbar`')
		}
	}

	paste() {
		if (this._plugins.clipboard) {
			if (this._cpids) {
				this._plugins.clipboard.paste(this._cpids)
				this.invalidateLabelFontSize()
			}
		} else {
			console.warn('Can not `paste`, please check the initialize option `editor.toolbar`')
		}
	}

	changeLevel(type = 0) {
		let selected = this.cy.$(':selected')
		if (selected.length) {
			selected.forEach(el => {
				let pre = el.style()
				el.style('z-index', pre.zIndex - 0 + type > -1 ? pre.zIndex - 0 + type : 0)
			})
		}
	}

	async toggleLeftPanel(){
		console.log("toggleLeftPanel")
		if(await this.notifyAction("toggleLeftPanel") === true){
			this.redrawGrid()
		}
	}



	async notifyAction(action, data){
		if(typeof this.editorOptions.actionListener === 'function'){
			let res = await this.editorOptions.actionListener({action}, data)
			console.log("Genogram.notifyAction: res = ", res)
			return res
		}
		return NO_LISTENER
	}

	redrawGrid(){
		window.dispatchEvent(new CustomEvent('resize'))
	}

	deleteSelected() {
		let selected = this.cy.$(':selected')
		selected = selected.filter(item => !item.data('nonDeletable'))
		if (selected.length) {
			if (this._plugins.undoRedo) {
				this._undoRedoAction('remove', selected)
			}
			this.cy.remove(selected)
			this.cy.trigger('cyeditor.remove')
		}
	}

	async onBeforeSave(){
		let res = await this.notifyAction("exportGenogram")
		console.log("Genogram.onBeforeSave: res = ", res)
		return res === NO_LISTENER || res //return true if no listener, otherwise return the result
	}

	async save() {
		this.unselectAll()
		let boundingBox = this.cy.elements().boundingBox();
		console.log("getGenogramBase64Image: boundingBox = ", boundingBox)

		if(boundingBox.w > 10000 || boundingBox.h > 10000){
			let timesVar = Math.max(boundingBox.w / 10000, boundingBox.h / 10000)
			if(timesVar < 2){
				timesVar = "a bit"
			} else {
				timesVar = Math.ceil(timesVar)
			}
			let res = await this.notifyAction("showMessage", {
				title: "Genogram is too wide",
				message: `The resulting export may have poor quality or may fail entirely.<br/><br/>Please try to make your genogram ${timesVar} more compact and try again.`,
				okTitle: "Try exporting anyway",
			})
			if(!res){
				return
			}
		}

		if(!await this.onBeforeSave()){
			return
		}
		await this.saveGenogramToImage()
		this.analyticsManager.trackEvent("Genogram Save to Image clicked", {label: this.editorOptions.showWatermark ? "watermark added" : "no watermark"})
		await this.notifyAction("exportGenogramCompleted")
	}



	async saveGenogramToImage(){

		let [genogramImage, legendImage] = await Promise.all([
			this.getGenogramBase64Image(),
			this.getLegendBase64Image(),
		])

		let finalImage = await this.mergeTwoBase64Images(genogramImage, legendImage)

		let a = document.createElement('a')
		a.download = `my-genogram.png`
		a.href = finalImage
		a.click()
		a.remove()

	}

	getImageFromSource(imgSrc) {
		return new Promise (resolved => {
			let image = new Image()
			image.onload = () => resolved(image)
			image.src = imgSrc
		})
	}

	async mergeTwoBase64Images(base64img1, base64img2){
		let [img1, img2] = await Promise.all([
			this.getImageFromSource(base64img1),
			this.getImageFromSource(base64img2),
		])

		let padding = 20
		var canvas = document.createElement("canvas");
		canvas.width = img1.width + img2.width + 3 * padding; //left padding, right padding, padding between genogram and legend
		canvas.height = Math.max(img1.height, img2.height)  + 2 * padding;


		// Copy the image contents to the canvas
		var ctx = canvas.getContext("2d");
		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, canvas.width, canvas.height);


		ctx.drawImage(img1, padding, padding);

		ctx.fillStyle = "grey";
		let legendBorderSize = 3
		ctx.fillRect(img1.width - legendBorderSize + 2 * padding, padding, img2.width, img2.height + legendBorderSize);
		ctx.drawImage(img2, img1.width + 2 * padding, padding);


		if(this.editorOptions.showWatermark){
			this.addText(ctx, canvas)
		}

		let finalImage = canvas.toDataURL("image/png");
		canvas.remove()
		return finalImage
	}

	addText(ctx, canvas){
		ctx.fillStyle = "rgba(60, 60, 60, 0.4)";
		let fontSize = 50
		ctx.font = ctx.font.replace(/\d+px/, fontSize + "px");
		console.log("ctx.font 2 = ", ctx.font)


		// let lines = ["This Genogram was ", "Built With Qwoach.", "", 'You are in a Free Trial,', "Upgrade to Remove This Watermark."]
		let lines = ["This Genogram was ", "Built With Qwoach.", "", 'To Remove This Watermark,', "Please Save This Genogram First."]
		let lineHeight = fontSize
		let height = lineHeight * lines.length
		let quadrantWidth = 900
		let quadrantHeight = 400
		let left = 0
		let top = 0
		while (left < canvas.width){
			while (top < canvas.height){
				this.drawText(ctx, lines, left, top, quadrantWidth, quadrantHeight, lineHeight, height)

				top += quadrantHeight
			}
			top = 0
			left += quadrantWidth
		}
	}

	drawText(ctx, lines, x1, y1, quadrantWidth, quadrantHeight, lineHeight, textHeight){
		let lineIndex = 0
		for(let line of lines){
			let {width} = ctx.measureText(line)
			let x = x1 + (quadrantWidth - width)/ 2
			let y = y1 + (quadrantHeight - textHeight)/ 2 + lineIndex * lineHeight
			ctx.fillText(line, x, y)
			console.log("draw line: width = ", width, ", x = ", x, ", y = ", y)
			lineIndex++
		}
	}

	async getGenogramBase64Image(){
		let blob = await this.cy.jpg({
			maxWidth: 20000,
			output: 'blob-promise',
			// output: 'base64',
			bg: 'white',
			full: true,
		})
		console.log("getGenogramBase64Image: blob = ", blob)
		return window.URL.createObjectURL(blob)
	}

	async getLegendBase64Image(){
		let emptyPng = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
		let image = await this.emit('requestLegendSnapshot')
		return image || emptyPng
	}

	fit() {
		this.cy.fit()
		// if (!this._fit_status) {
		// 	this._fit_status = {pan: this.cy.pan(), zoom: this.cy.zoom()}
		// 	this.cy.fit()
		// } else {
		// 	this.cy.viewport({
		// 		zoom: this._fit_status.zoom,
		// 		pan: this._fit_status.pan
		// 	})
		// 	this._fit_status = null
		// }
	}

	zoom(type = 1, level) {
		level = level || this.editorOptions.zoomRate
		let w = this.cy.width()
		let h = this.cy.height()
		let zoom = this.cy.zoom() + level * type
		let pan = this.cy.pan()
		// pan.x = pan.x + -1 * w * level * type / 2
		// pan.y = pan.y + -1 * h * level * type / 2
		console.log("zoom: ", w, h, zoom, pan)
		this.cy.zoom({
				level: zoom,
				renderedPosition: {
					x: w/2,
					y: h/2
				}
		})

		// this.cy.viewport({
		// 	zoom,
		// 	pan
		// })
	}

	toggleGrid() {
		if (this._plugins.cySnapToGrid) {
			this.setOption('snapGrid', !this.editorOptions.snapGrid)
		} else {
			console.warn('Can not `toggleGrid`, please check the initialize option')
		}
	}

	jpg(opt = {}) {
		return this.cy.png(opt)
	}

	png(opt) {
		return this.cy.png(opt)
	}

	/**
	 * Export the graph as JSON or Import the graph as JSON
	 * @param {*} opt params for cy.json(opt)
	 * @param {*} keys JSON Object keys
	 */
	json(opt = false, keys) {
		keys = keys || ['boxSelectionEnabled', 'elements', 'pan', 'panningEnabled', 'userPanningEnabled', 'userZoomingEnabled', 'zoom', 'zoomingEnabled']
		// export
		let json = {}
		if (typeof opt === 'boolean') {
			let cyjson = this.cy.json(opt)
			keys.forEach(key => {
				json[key] = cyjson[key]
			})
			return json
		}
		// import
		if (typeof opt === 'object') {
			json = {}
			keys.forEach(key => {
				json[key] = opt[key]
			})
		}

		return this.cy.json(json)
	}

	/**
	 * get or set data
	 * @param {string|object} name
	 * @param {*} value
	 */
	data(name, value) {
		return this.cy.data(name, value)
	}

	/**
	 *  remove data
	 * @param {string} names  split by space
	 */
	removeData(names) {
		this.cy.removeData(names)
	}

	destroy() {
		this.cy.removeAllListeners()
		this.cy.destroy()
		this.keyActionListener.destroy()
	}
}

export default CyEditor
