import {
	createAuxNode
} from "@/core/recoveryCoachingApp/coach/clients/ClientDetails/Genogram/GenogramEditor/lib/genogramUtils";

const isString = x => typeof x === typeof ''

function getEleJson(overrides, params, addedClasses) {
	let json = {}

	if(overrides && overrides.data && !overrides.data.id){
		overrides.data.id = "" + new Date().getTime()
	}
	// basic values
	Object.assign(json, params, overrides)

	// make sure params can specify data but that overrides take precedence
	Object.assign(json.data, params.data, overrides.data)

	if (isString(params.classes)) {
		json.classes = params.classes + ' ' + addedClasses
	} else if (Array.isArray(params.classes)) {
		json.classes = params.classes.join(' ') + ' ' + addedClasses
	} else {
		json.classes = addedClasses
	}

	return json
}

function makeEdges(preview = false, event) {
	let {
		cy,
		options,
		presumptiveTargets,
		previewEles,
		active
	} = this

	let source = this.sourceNode
	let target = this.targetNode
	let classes = preview ? 'eh-preview' : ''
	let added = cy.collection()
	let edgeType = options.edgeType(source, target)

	let data = target.data() || {}
	let {lineType, lineSubType} = data
	// console.log("edgehandles: target.data() = ", target.data())
	if (lineSubType && lineSubType.startsWith("family-")) {
		edgeType = lineSubType
	}

	// console.log("edgehandles: edgeType = ", edgeType)

	// can't make edges outside of regular gesture lifecycle
	if (!active) {
		return
	}

	// must have a non-empty edge type
	if (!edgeType) {
		return
	}

	// can't make preview if disabled
	if (preview && !options.preview) {
		return
	}

	// detect cancel
	if (!target || target.size() === 0) {
		previewEles.remove()

		this.emit('cancel', this.mp(), source, presumptiveTargets)

		return
	}

	// just remove preview class if we already have the edges
	if (!preview && options.preview) {
		previewEles.removeClass('eh-preview').style('events', '')

		this.emit('complete', this.mp(), source, target, previewEles)

		return
	}

	let p1 = source.position()
	let p2 = target.position()

	let p
	if (source.same(target)) {
		p = {
			x: p1.x + options.nodeLoopOffset,
			y: p1.y + options.nodeLoopOffset
		}
	} else {
		p = {
			x: (p1.x + p2.x) / 2,
			y: (p1.y + p2.y) / 2
		}
	}
	if (edgeType.startsWith("family-")) {
		let connectivityNode = createConnectivityNode.bind(this)(source, target, event)
		if(connectivityNode){

			let source2target = cy.add(
				getEleJson({
						group: 'edges',
						data: {
							source: source.id(),
							target: connectivityNode.id()
						}
					},
					options.edgeParams(source, connectivityNode, 0),
					classes
				)
			)

			added = added.merge(source2target).merge(connectivityNode)
			this.connectivityNode = connectivityNode
		}


	} else if (edgeType === 'node') {
		let interNode = cy.add(
			getEleJson({
					group: 'nodes',
					position: p
				},
				options.nodeParams(source, target),
				classes
			))

		let source2inter = cy.add(
			getEleJson({
					group: 'edges',
					data: {
						source: source.id(),
						target: interNode.id()
					}
				},
				options.edgeParams(source, target, 0),
				classes
			)
		)

		let inter2target = cy.add(
			getEleJson({
					group: 'edges',
					data: {
						source: interNode.id(),
						target: target.id()
					}
				},
				options.edgeParams(source, target, 1),
				classes
			)
		)

		added = added.merge(interNode).merge(source2inter).merge(inter2target)
	} else { // flat
		let source2target = cy.add(
			getEleJson({
					group: 'edges',
					data: {
						source: source.id(),
						target: target.id()
					}
				},
				options.edgeParams(source, target, 0),
				classes
			)
		)

		added = added.merge(source2target)
	}

	if (preview) {
		this.previewEles = added

		added.style('events', 'no')
	} else {
		added.style('events', '')

		this.emit('complete', this.mp(), source, target, added)
	}

	return this
}

// function createConnectivityNode(source, edge, event) {
// 	let {
// 		cy
// 	} = this
//
// 	console.log("edgehandles: createConnectivityNode: event = ", event)
//
// 	let [pt1, pt2] = edge.segmentPoints()
// 	let x1 = pt1.x
// 	let y1 = pt1.y
// 	let x2 = pt2.x
// 	let y2 = pt2.y
//
// 	let targetX = event.position.x
//
// 	// if (targetX <= x1 || x2 <= targetX) {
// 	// 	return
// 	// }
// 	if (targetX <= x1){
// 		targetX = x1
// 	}
// 	if (x2 <= targetX){
// 		targetX = x2
// 	}
//
// 	let xPositionPercent = (targetX - x1) / (x2 - x1)
//
// 	let auxNodeId = 'aux_' + new Date().getTime()
// 	let auxNodes = edge.data().auxNodes || []
// 	auxNodes.push(auxNodeId)
// 	edge.data({auxNodes})
//
// 	let dragCreatedNode = cy.add([
// 		{
// 			data: {
// 				isAuxNode: true,
// 				parentEdgeId: edge.data().id,
// 				xPositionPercent: xPositionPercent,
// 				type: 'round-rectangle',
// 				name: '',
// 				resize: true,
// 				// bg: '#1890FF',
// 				width: 10,
// 				height: 10,
// 				id: auxNodeId,
// 				skipSnapToGridY: true
// 			},
// 			position: {
// 				x: targetX,
// 				y: y1
// 			}
// 		}
// 	])//.lock()
//
// 	return dragCreatedNode
// }


function createConnectivityNode(source, edge, event) {
	let {
		cy
	} = this

	console.log("edgehandles: createConnectivityNode: event = ", event)

	let [pt1, pt2] = edge.segmentPoints()
	let x1 = pt1.x
	let y1 = pt1.y
	let x2 = pt2.x
	let y2 = pt2.y

	let targetX = event.position.x

	// if (targetX <= x1 || x2 <= targetX) {
	// 	return
	// }
	if (targetX <= x1){
		targetX = x1
	}
	if (x2 <= targetX){
		targetX = x2
	}

	let xPositionPercent = (targetX - x1) / (x2 - x1)

	let auxNode = createAuxNode({
		parentEdge: edge,
		childNode: source,
		x: targetX,
		xPositionPercent
	})
	let dragCreatedNode = cy.add(auxNode)

	console.log("edgehandles: createConnectivityNode: dragCreatedNode = ", dragCreatedNode, ", auxNode = ", auxNode)

	// let auxNodeId = 'aux_' + new Date().getTime()
	// let auxNodes = edge.data().auxNodes || []
	// auxNodes.push(auxNodeId)
	// edge.data({auxNodes})
	//
	// let dragCreatedNode = cy.add([
	// 	{
	// 		data: {
	// 			isAuxNode: true,
	// 			parentEdgeId: edge.data().id,
	// 			xPositionPercent: xPositionPercent,
	// 			type: 'round-rectangle',
	// 			name: '',
	// 			resize: true,
	// 			// bg: '#1890FF',
	// 			width: 10,
	// 			height: 10,
	// 			id: auxNodeId,
	// 			skipSnapToGridY: true
	// 		},
	// 		position: {
	// 			x: targetX,
	// 			y: y1
	// 		}
	// 	}
	// ])//.lock()

	return dragCreatedNode
}

function makePreview(event) {
	this.makeEdges(true, event)

	return this
}

function previewShown() {
	return this.previewEles.nonempty() && this.previewEles.inside()
}

function removePreview() {
	if (this.previewShown()) {
		this.previewEles.remove()
	}

	return this
}

function handleShown() {
	return this.handleNode.nonempty() && this.handleNode.inside()
}

function removeHandle() {
	// console.trace("removeHandle")
	if (this.handleShown()) {
		this.handleNode.remove()
	}

	return this
}

function setHandleFor(node) {
	let {
		options,
		cy
	} = this

	let handlePosition = typeof options.handlePosition === typeof '' ? () => options.handlePosition : options.handlePosition

	let p = node.position()
	let h = node.outerHeight()
	let w = node.outerWidth()

	// store how much we should move the handle from origin(p.x, p.y)
	let moveX = 0
	let moveY = 0

	// grab axes
	let axes = handlePosition(node).toLowerCase().split(/\s+/)
	let axisX = axes[0]
	let axisY = axes[1]

	// based on handlePosition move left/right/top/bottom. Middle/middle will just be normal
	if (axisX === 'left') {
		moveX = -(w / 2)
	} else if (axisX === 'right') {
		moveX = w / 2
	}
	if (axisY === 'top') {
		moveY = -(h / 2)
	} else if (axisY === 'bottom') {
		moveY = h / 2
	}

	// set handle x and y based on adjusted positions
	let hx = this.hx = p.x + moveX
	let hy = this.hy = p.y + moveY
	let pos = {
		x: hx,
		y: hy
	}

	if (this.handleShown()) {
		this.handleNode.position(pos)
	} else {
		cy.batch(() => {
			this.handleNode = cy.add({
				classes: 'eh-handle',
				position: pos,
				grabbable: false,
				selectable: false,
				data: {
					tempElement: true,
					isHandle: true
				}
			})

			this.handleNode.style('z-index', 9007199254740991)
		})
	}

	return this
}

function updateEdge() {
	let {
		sourceNode,
		ghostNode,
		connectivityNode,
		cy,
		mx,
		my,
		options
	} = this
	let x = mx
	let y = my
	let ghostEdge, ghostEles

	// can't draw a line without having the starting node
	if (!sourceNode) {
		return
	}

	if (!ghostNode || ghostNode.length === 0 || ghostNode.removed()) {
		ghostEles = this.ghostEles = cy.collection()

		cy.batch(() => {
			ghostNode = this.ghostNode = cy.add({
				group: 'nodes',
				classes: 'eh-ghost eh-ghost-node',
				position: {
					x: 0,
					y: 0
				}
			})

			ghostNode.style({
				'background-color': 'blue',
				'width': 0.0001,
				'height': 0.0001,
				'opacity': 0,
				'events': 'no'
			})

			let ghostEdgeParams = options.ghostEdgeParams()

			ghostEdge = cy.add(Object.assign({}, ghostEdgeParams, {
				group: 'edges',
				data: Object.assign({}, ghostEdgeParams.data, {
					source: sourceNode.id(),
					target: ghostNode.id()
				})
			}))

			ghostEdge.addClass('eh-ghost eh-ghost-edge')

			ghostEdge.style({
				'events': 'no'
			})
		})

		ghostEles.merge(ghostNode).merge(ghostEdge)
	}

	ghostNode.position({
		x,
		y
	})

	if (connectivityNode && connectivityNode.data().isAuxNode) {
		// console.log("position: e = ", e)
		let edge = this.cy.getElementById(connectivityNode.data().parentEdgeId)
		if(edge.length === 0){
			cy.remove(connectivityNode)
			this.connectivityNode = null
		}
		let [pt1, pt2] = edge.segmentPoints()
		let x1 = pt1.x
		let y1 = pt1.y
		let x2 = pt2.x
		let snappedX = x
		snappedX = Math.min(x2, Math.max(x1, snappedX))
		let newPosition = {
			x: snappedX,
			y: y1
		}
		connectivityNode.position(newPosition)

		// connectivityNode.position({
		// 	x,
		// 	y
		// })
	}


	return this
}

export default {
	makeEdges,
	makePreview,
	removePreview,
	previewShown,
	updateEdge,
	handleShown,
	setHandleFor,
	removeHandle
}
