import {defaultNodeTypes, defaultNodeStyles} from './node-types'
import {defaultEdgeStyles} from './edge-types'
import pluginStyles from './plugin-style'
import GenogramRenderer
	from "@/core/recoveryCoachingApp/coach/clients/ClientDetails/Genogram/GenogramEditor/lib/GenogramRenderer.js";

export default {
	cy: {

		layout: {
			name: 'concentric',
			fit: false,
			concentric: function (n) {
				return 0
			},
			minNodeSpacing: 100
		},
		styleEnabled: true,
		style: [
			...defaultEdgeStyles,
			...defaultNodeStyles,
			...pluginStyles
		],
		minZoom: 0.05,
		maxZoom: 2,

		renderer: {
			name: 'genogramRenderer',
			clazz: GenogramRenderer,
		},

	},
	editor: {
		container: '',
		zoomRate: 0.2,
		lineType: 'straight',
		noderesize: false,
		dragAddNodes: true,
		compoundDnd: {
			isEnabled: false,
			grabbedNode: node => true, // filter function to specify which nodes are valid to grab and drop into other nodes
			dropTarget: (dropTarget, grabbedNode) => true, // filter function to specify which parent nodes are valid drop targets
			dropSibling: (dropSibling, grabbedNode) => true, // filter function to specify which orphan nodes are valid drop siblings
			newParentNode: (grabbedNode, dropSibling) => ({}), // specifies element json for parent nodes added by dropping an orphan node on another orphan (a drop sibling)
			boundingBoxOptions: { // same as https://js.cytoscape.org/#eles.boundingBox, used when calculating if one node is dragged over another
				includeOverlays: false,
				includeLabels: true
			},
			overThreshold: 10, // make dragging over a drop target easier by expanding the hit area by this amount on all sides
			outThreshold: 100,
		},
		templates: true,
		elementsInfo: false,
		toolbar: true, // boolean or array: ['undo', 'redo', 'copy', 'paste', 'delete', 'zoomin', 'zoomout', 'fit', 'leveldown', 'levelup', 'gridon', 'boxselect', 'line-straight', 'line-taxi', 'line-bezier', 'save']
		snapGrid: true,
		contextMenu: false,
		navigator: false,
		useDefaultNodeTypes: true, // whether nodeTypes should concat with defaultNodeTypes
		nodeTypes: defaultNodeTypes,
		autoSave: true, // Todo
		beforeAdd(el) {
			return true
		},
		afterAdd(el) {

		}
	}
}
