<template>
	<div></div>
</template>
<script>

    import DebounceVModelView from "@/core/components/DebouncedVModelView.vue";
    export default {
        name: "BaseFormRow",
        extends: DebounceVModelView,
		props: {
			prop: String,
			title: String,
			titlePostfix: String,
			titlePrefix: String,
			placeholder: {type:String, default: "type here"},
			tooltipText: String,
			tooltipPosition: String,
			showDivider: {Boolean, default: true},
			showDividerTop: {Boolean, default: false},
			formatDataInProp: Function,
			formatDataOutProp: Function,
			isFocused: {Boolean, default: false},
			readonly: {Boolean, default: false},
			disabled: {Boolean, default: false},
		},

		watch:{
			isFocused:{
				immediate: true,
				handler(){
					this.isFocusedData = this.isFocused
				}
			}
		},

		data(){
        	return {
				isFocusedData: false
			}
		},

		methods: {
        	onFocus(){
        		if(!this.readonly && !this.disabled){
					this.isFocusedData = true
				}
				this.clearFormValidation()
				this.$emit("focus")
			},
			onBlur(){
				this.isFocusedData = false
				this.$emit("blur")
			}
		}
    }
</script>

<style>

</style>

