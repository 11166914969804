import {numbersFromString} from "@/utils/helpers";

const screens = {
	'xs-max': 543,
	'sm-min': 544,
	'sm-max': 767,
	'md-min': 768,
	'md-max': 991,
	'lg-min': 992,
	'lg-max': 1199,
	'xl-min': 1200,
};

export function isScreenBig() {
	return isScreen('lg') || isScreen('xl')
}

export function isScreenSmall() {
	return !isScreenBig()
}

export function isScreen(size) {
	const screenPx = window.innerWidth;
	return (screenPx >= screens[`${size}-min`] || size === 'xs')
		&& (screenPx <= screens[`${size}-max`] || size === 'xl');
}

export const MediaBreakpointEnum = {
	XS: 'xs',
	SM: 'sm',
	MD: 'md',
	LG: 'lg',
	XL: 'xl',

}

export function isTouchDevice() {
	return (('ontouchstart' in window) ||
		(navigator.maxTouchPoints > 0) ||
		(navigator.msMaxTouchPoints > 0));
}

function getBreakpoint(breakpoint) {
	let style = getComputedStyle(document.body)
	//--bs-breakpoin-{..} is BS5+ and --breakpoint-{..} is BS4
	let breakpointValue = style.getPropertyValue('--bs-breakpoint-' + breakpoint) || style.getPropertyValue('--breakpoint-' + breakpoint)
	return numbersFromString(breakpointValue)[0]
}

export function isMediaBreakpointDown(breakpointName) {
	let breakpoint = getBreakpoint(breakpointName)
	let screenPx = window.innerWidth
	let res = screenPx < breakpoint
	console.log("isMediaBreakpointDown: breakpointName = ", breakpointName, ", breakpoint = ", breakpoint, ", screenPx = ", screenPx, ", isDown = ", res)
	return screenPx < breakpoint
}

export function isMediaBreakpointUp(breakpointName) {
	let breakpoint = getBreakpoint(breakpointName)
	let screenPx = window.innerWidth
	let res = screenPx >= breakpoint
	console.log("isMediaBreakpointUp: breakpointName = ", breakpointName, ", breakpoint = ", breakpoint, ", isUp = ", res)
	return res
}

export function isMobileScreen(){
	return isMediaBreakpointDown(MediaBreakpointEnum.LG)
}

