<script>
import { mapState } from 'vuex'
import UserProfile from "@/core/js/model/Profile/UserProfile";
import {plainToClass} from "class-transformer";
import {
	asBase64, capitalizeFirstLetter,
	// findChildComponentRecursively,
	fromBase64, getClassName,
	isObject, logError,
	printContent,
	ProgressEnum
} from "@/utils/helpers";
// import {logError} from "@/errorHandler";
import BaseObject from "@shared/BaseObject";
import {classToClass} from "@qwoach/class-transformer-wrapper";
import UserSubscription from "@shared/Subscription/UserSubscription.js";
import CurrencyItem from "@shared/Profile/CurrencyItem";

// import Vue from "@/setupVue3.js";
// import QwForm from "@/core/components/Controls/QwForm.vue";
// function logError(...args){
// 	console.error("Error logging is broken!!! Fix circular dependencies in BaseMixinComponent first")
// }

export default {
	name: "BaseMixinComponent",

	data() {
		return {
			isDataLoading: false,
			isProgressShowing: false,
			isError: false,
			errorMessage: false,
			progressText:"Loading...",
			progressShowCounter: 0,
			// isGlobalBlockingProgressShowing: false,
		}
	},
	computed:{
		...mapState({
			currentUser: state => state.store.currentUser,
			userProfile: state => plainToClass(UserProfile, state.store.userProfile, { excludePrefixes: ['_'] }),
			coachProfile: state => plainToClass(UserProfile, state.store.coachProfile, { excludePrefixes: ['_'] }),
			ownerProfile: state => plainToClass(UserProfile, state.store.ownerProfile, { excludePrefixes: ['_'] }),
			embeddedCoachProfile: state => plainToClass(UserProfile, state.store.embeddedCoachProfile, { excludePrefixes: ['_'] }),
			userSubscription: state => plainToClass(UserSubscription, state.store.ownerSubscription || state.store.userSubscription),
			ownerSubscription: state => plainToClass(UserSubscription, state.store.ownerSubscription),
			isQwoachEmbedded: state => state.store.isQwoachEmbedded || state.store.embeddedUid != null,
			coachEmbeddedUid: state => state.store.embeddedUid,
			isSidebarOpen: state => !state.layout.sidebarClose

		}),

		routesPrefix(){
			return this.isAppV2Plus ? "Recovery" : ""
		},

		isOwner(){
			return this.userProfile && this.userProfile.isOwner();
		},
		isCoach(){
			return this.userProfile && this.userProfile.isCoach();
		},
		coachEmail(){
			return this.userProfile && this.userProfile.email;
		},
		isClient(){
			return this.userProfile && this.userProfile.isClient();
		},

		getOwnerProfileId(){
			return this.ownerProfileId
		},
		ownerProfileId(){
			return this.ownerProfile && this.ownerProfile.id
		},
		getOwnerProfile(){
			return this.ownerProfile || this.userProfile
		},

		otherPartyTitle(){
			return this.isCoach ? "client" : "coach"
		},

		isRecoveryApp(){
			return window.location.href.indexOf("recovery") > 0
		},
		isAppV2Plus(){
			return this.userProfile ? this.userProfile.appVersion >= 2 : false
		},

		accountType(){
			let accountType
			if(this.userProfile){
				accountType = this.userProfile.accountType
			}
			if(!accountType && this.userSubscription){
				accountType = this.userSubscription.accountType
			}

			return accountType

		},



		currencyItemGlobal(){
			console.log("currencyItemGlobal: this.ownerProfile.currencyItem = ", this.ownerProfile.currencyItem)
			return this.ownerProfile.currencyItem
		},
		currencyString(){
			return this.ownerProfile.currencyItem.toString()
		},

		isTeamFeatureEnabled(){
			return this.userProfile.isTeamEnabled()
		},

		isRedirectParamsValid(){
			let route = this.readRedirectParams()
			return !!route && !!route.routeName && !!route.params && !!route.query
		},
		redirectParamsOriginTitle(){
			let routeParams = this.readRedirectParams()
			return routeParams ? routeParams.originTitle : ""
		},

		getDeviceClientId(){
			if(this.userProfile && this.userProfile.isClient()){
				return this.userProfile.id
			}
			return this.$route.query.clientId || this.cookie.clientId.get()
		}


	},
	methods: {

		async logError(error, info){
			await logError(error, info)
			// await Vue.config.globalProperties.logError(...args)
			// await Vue.config.globalProperties.logError(...args)
			// console.warn("logError is not implemented !!!!!!!")
		},

		hasSlotContent(slotName){
			let slot = this.$slots[slotName]
			let slots = slot && slot()
			console.log("hasSlot: slots = ", slots, ", name = ", name, ", this.$slots = ", this.$slots)
			let hasText = slots && slots.length ? !!slots[0].text || !!slots[0].children.length: false
			return hasText
		},
		hasSlot(slotName){
			let slot = this.$slots[slotName]
			return !!slot && !!slot()
		},

		getBrandedLink(link){
			console.log("getBrandedLink: link = ", link, ", this.coachProfile = ", this.coachProfile, ", this.embeddedCoachProfile = ", this.embeddedCoachProfile)
			let coachProfile = this.embeddedCoachProfile || this.ownerProfile
			if(coachProfile){
				return coachProfile.getBrandedLink(link)
			}
			return link
		},

		getCoachId() {
			return this.ownerProfile.id;
		},

		resolvePathByRouteOptions(options) {
			let domain = process.env.VUE_APP_DOMAIN
			let url = domain + this.resolveRelative(options);
			return url
		},

		resolvePathByRouteName(name, params, query, options = {}) {
			if(typeof options === "string"){
				options = {domain: options}
			}
			let domain = options.domain || process.env.VUE_APP_DOMAIN
			let url = domain + this.resolveRelative({name, params, query});
			if(!options.ignoreBranding){
				url = this.getBrandedLink(url)
			}
			return url
		},

		resolveRelative(options) {
			console.log("resolveRelative: options = ", options)
			let {name, params, query} = options
			let url = this.$router.resolve({
				name,
				params,
				query
			}).href;
			console.log("resolveRelative: name = ", name, ", params = ", params, ", query = ", query, ", result url = ", url)
			return url
		},

		resolveRelativeSync(options) {
			let {name, params, query} = options
			name = name || this.$route.name
			params = Object.assign({}, this.$route.params, params)
			query = Object.assign({}, this.$route.query, query)
			return this.resolveRelative({name, params, query})
		},

		sessionFullUrl(sessionType, options = {}) {
			let {skipBranding, teamMemberId} = options
			let fullUrl = this.ownerProfile.schedulerSettings.schedulerRootUrl() + "/" + sessionType.displayId
			if(!teamMemberId && !this.isOwner && !this.isClient){
				teamMemberId = this.userProfile.id
			}
			if(teamMemberId){
				fullUrl += "?teamMemberId=" + teamMemberId
			}
			if(!skipBranding){
				fullUrl = this.getBrandedLink(fullUrl)
			}
			return fullUrl
		},

		async goSaveOrigin(name, params = {}, query = {}, redirectOriginTitle) {
			console.log("goSaveOrigin: ", ...arguments)
			let originRedirectParams = this.createRedirectParams(redirectOriginTitle)
			query = {
				...query,
				...originRedirectParams
			}
			console.log("goSaveOrigin: query = ", query)

			return this.go(name, params, query)
		},

		async removeBackRedirect(){
			return this.removeQuery(["qworgn"])
		},

		closeAllModals(){

		},

		async go(name, params, query, options = {}) {
			console.log("go: ", ...arguments)
			let opt = {}
			if(typeof name === "string"){
				opt = {name, params, query, ...options}
			} else {
				opt = name
			}
			return this.handleAsync(async () => {
				this.closeAllModals()
				return this.goOptions(opt)
			})
		},
		async goOptions(options) {
			console.log("go: options = ", options)
			// options.customState = options.customState || {}
			// if(options.params && options.params.customState){
			// 	Object.assign(options.customState, options.params.customState)
			// 	delete options.params.customState
			// }
			// if(options.customState){
			// 	let newState = {customProps: {}, customPropTypes: {}}
			// 	for(let key of Object.keys(options.customState)){
			// 		newState.customProps[key] = classToClass(options.customState[key])
			// 		//TODO: save type of the object to restore it later
			// 		newState.customPropTypes[key] = getClassName(options.customState[key])
			// 	}
			// 	options.state = newState
			// 	delete options.customState
			// }
			let state = options.state || options.params
			if(state){
				window.qwRouteGlobalState = window.qwRouteGlobalState || {}
				window.qwRouteGlobalState = {...window.qwRouteGlobalState, ...state}
				delete options.state
				console.log("goOptions: window.qwRouteGlobalState = ", window.qwRouteGlobalState)
			}
			try{
				this.$analyticManager.trackEvent("Navigation", {
					label: options.name,
					options: options,
					...this.analyticsPropsMethod(),
				})
			}catch (error){
				console.error(error)
			}
			if(options.skipHistory){
				return this.$router.replace(options).catch(error => console.log(error))
			} else {
				return this.$router.push(options).catch(error => console.log(error))
			}
		},
		async goSync(name, params = {}, query = {}, options = {}) {
			if (isObject(name)) {
				params = name.params || {}
				query = name.query || {}
				name = name.name
			}
			console.log("goSync: params = ", params)
			let stateParams = params
			name = name || this.$route.name
			params = Object.assign({}, this.$route.params, params)
			let queryMerged = Object.assign({}, this.$route.query, query)
			Object.keys(query).forEach(key => {
				if (query[key] === null) {
					delete queryMerged[key]
				}
			})
			return this.goOptions({name, params, query: queryMerged, state: stateParams, ...options})
		},
		async goPath(path) {
			console.log("goPath: path = ", path)
			return this.$router.push({path}).catch(error => console.log(error))
		},
		goUrl(url) {
			window.location.href = url;
		},
		goBack(steps) {
			this.$router.go(steps || -1)
		},
		_showProgressDataLoadingInternal(message) {
			if (message) {
				this.progressText = message
			}
			this.isDataLoading = true;
			this.isProgressShowing = true;
			console.log("progress: show data loading")
		},
		_showProgressNonBlockingInternal() {
			this.$Progress.start();
			this.isProgressShowing = true;
			console.log("progress: show non-blocking")
		},

		_showProgressBlockingInternal(message) {
			if (message) {
				this.getAppComponent().progressText = message
			} else {
				this.getAppComponent().progressText = "Loading..."
			}
			this.getAppComponent().isGlobalBlockingProgressShowing = true

			this.isProgressShowing = true;
			console.log("progress: show blocking")
		},



		async removeQuery(queryNameArray) {
			console.log("removeQuery: ", queryNameArray)
			let obj = {}
			queryNameArray.forEach(key => {
				obj[key] = null
			})
			return this.setQuery(obj)
		},

		getQueryParam(key) {
			return this.$route.query[key]
		},

		async setQuery(query, options = {}) {
			// console.log("setQuery: ", query)
			let obj = Object.assign({}, this.$route.query);
			let {addToBrowserHistory} = options

			console.log("setQuery: this.$route.query = ", this.$route.query, ", query = ", query)

			let isPathDifferent = false

			Object.keys(query).forEach(key => {
				let value = query[key];
				if (value !== null && value !== undefined) {
					obj[key] = value
				} else {
					delete obj[key]
				}
				let queryValue = this.$route.query[key]
				if (queryValue === "true") {
					queryValue = true
				}
				if (queryValue === "false") {
					queryValue = false
				}
				console.log("setQuery: queryValue = ", queryValue, ", value = ", value, ", key = ", key, ", queryValue !== value: ", queryValue !== value)
				let valueStr = "" + value
				if (queryValue !== valueStr && !(value === null && queryValue === undefined)) {
					isPathDifferent = true
				}
			})
			console.log("setQuery: isPathDifferent = ", isPathDifferent)


			console.log("setQuery: this.$router.currentRoute = ", this.$router.currentRoute)
			if (!isPathDifferent) {
				console.log("setQuery: query path is the same. Skipping the navigation.")
				return
			}
			try{
				let method = addToBrowserHistory ? "push" : "replace"
				await this.$router[method]({
					...this.$router.currentRoute,
					query: obj
				})
				return true
			} catch (error){
				console.warn(error)
				return false
			}
		},

		updatePathParams(newParams) {

			const currentParams = this.$route.params;
			const mergedParams = {...currentParams, ...newParams};
			console.log("updatePathParams: mergedParams = ", mergedParams)
			this.$router.push({params: mergedParams});
		},


		progressBlocking(message) {
			return this._progressInternal(ProgressEnum.BLOCKING, message)
		},
		progressNonBlocking(message) {
			return this._progressInternal(ProgressEnum.NOT_BLOCKING, message)
		},
		progressDataLoading(message) {
			return this._progressInternal(ProgressEnum.DATA_LOADING, message)
		},
		showProgress(message) {
			return this.progressDataLoading(message)
		},

		getProgressIndicator() {
			return this.$refs.loadingIndicator
		},

		_progressInternal(showProgress = ProgressEnum.NOT_BLOCKING, message = "Loading...") {
			console.log("showProgress: ", showProgress)
			this.isError = false
			this.errorMessage = ""
			switch (showProgress) {
				case ProgressEnum.BLOCKING:
					this._showProgressBlockingInternal(message)
					break;
				case ProgressEnum.NOT_BLOCKING:
					this._showProgressNonBlockingInternal()
					break;
				case ProgressEnum.DATA_LOADING: {
					this._showProgressDataLoadingInternal(message)
					break;
				}
			}
			let promiseProvider = {
				for: async promiseFn => {
					if (promiseFn) {
						return Promise.resolve()
							.then(_ => {
								let promise = promiseFn()
								if (Array.isArray(promise)) {
									promise = Promise.all(promise)
								}
								return promise;
							})
							.then(result => {
								this.hideProgress()
								return result;
							}).catch(error => {
								this.hideProgressFail()
								this.logError(error)
								throw error;
							})
					}
				}
			}
			return promiseProvider
		},

		async copyToClipboard(text, successMessage = "Copied to clipboard.") {
			try {
				await this.$copyText(text)
				this.toast(successMessage)
			} catch (error) {
				this.handleError(error)
			}
		},

		async validateForm(formName) {
			let res = await this.validateFormRes(formName)
			console.log("validateForm: res = ", res)
			res ? this.onFormValidated(formName) : this.onFormFailed(formName)
			return res
		},

		async validateFormRes(formName) {
			let res
			console.log("validateFormRes1: res = ", res, ", formName = ", formName)
			try {
				res = await this.validateFormInternal(formName)
				console.log("validateFormRes2: res = ", res, ", formName = ", formName)
			} catch (error) {
				console.error("validateFormRes: error = ", error, ", formName = ", formName)
				res = false
			}
			return res
		},

		findElement(){

		},


		async validateFormInternal(formName = "mainForm") {
			return new Promise((resolve, reject) => {
				console.log("formName: ", formName)
				let form
				if (typeof formName === 'string') {
					form = this.$refs[formName]
				} else {
					form = formName
				}
				// if(!form){
				// 	form = findChildComponentRecursively(this, QwForm)
				// }

				console.log("validateForm: form = ", form, ", form.model = ", (form || {}).model, ", form.rules = ", (form || {}).rules)
				if (form) {
					form.validate(isValid => {
						console.log("validateForm: formName = ", formName, ", isValid = ", isValid)
						if (isValid) {
							resolve(isValid)
						} else {
							reject(isValid)
						}
					})
				} else {
					console.warn("validateForm: form not found: ", formName)
					resolve(true)
				}
			})
		},
		submitFormPromise(formNameArray, params) {
			let formName = formNameArray || "mainForm";
			console.log("submitFormPromise: formName = ", formName)

			if (!Array.isArray(formName)) {
				formName = [formName]
			}
			return Promise.all(formName.map(name => this.validateFormInternal(name, params)))
		},


		async printContent(){
			printContent()
		},


		async submitForm(formNameArray, params) {
			console.log("submitForm1: formNameArray = ", formNameArray)
			try {
				await this.submitFormPromise(formNameArray, params)
				let _ = this.$analyticManager.trackEvent("Continue clicked", {
					category: "Register screen",
					label: "form validation success", ...this.analyticsPropsMethod()
				})
				return this.onFormValidated(formNameArray, params)
			} catch (error) {
				let _ = this.$analyticManager.trackEvent("Continue clicked", {
					category: "Register screen",
					label: "form validation error", ...this.analyticsPropsMethod()
				})
				console.error("submitForm failed: ", error)
				return this.onFormFailed(formNameArray, params, error)
			}
		},

		async emitPromise(method, ...params) {
			let listener = this.findListenerByName(method)
			if (listener) {
				console.log("emitPromise: method = ", method, ", params = ", ...params)
				let res = await listener(...params)
				console.log("emitPromise: method = ", method, ", res = ", res)
				return res || res === undefined
			}
			return true
		},

		hasListenerByName(listenerName){
			return this.findListenerByNameInternal(listenerName) != null
		},

		emitResult(method, ...params) {
			let listener = this.findListenerByName(method)
			if (listener) {
				let res = listener(...params)
				return res === undefined || res
			}
			return false
		},

		findListenerByName(method) {
			return this.findListenerByNameInternal(method)
		},

		findListenerByNameInternal(method) {
			if (method) {
				let onMethod = "on" + capitalizeFirstLetter(method)
				return this.$attrs[method] || this.$props[method] || this.$attrs[onMethod] || this.$props[onMethod]
			}
			return false
		},

		async handleAsyncThrow(promise, options = {}) {
			if(typeof options === "string"){
				options = {message: options}
			}
			this.progressDataLoading(options.message)
			return await this.handleAsyncInternal(promise, {throwOnError: true, ...options})
		},

		async handleAsync(promise, options = {}, options2 = {}) {
			this.progressShowCounter++
			console.log("---> progress: show handleAsync: this.progressShowCounter = ", this.progressShowCounter, ", this = ", this, ", this._uid=" + this._uid)
			if(typeof options === "string"){
				options = {message: options}
			}
			if(isObject(options2)){
				options = {
					...options,
					...options2
				}
			}

			switch (options.progressType) {
				case ProgressEnum.DONT_SHOW:
					break;
				case ProgressEnum.NOT_BLOCKING:
					this.progressNonBlocking(options.message)
					break;
				case ProgressEnum.BLOCKING:
					this.progressBlocking(options.message)
					break;
				default:
					this.progressDataLoading(options.message)
			}

			return await this.handleAsyncInternal(promise, options)
		},


		async handleAsyncBlocking(promise, message) {
			this.progressBlocking(message)
			return await this.handleAsyncInternal(promise)
		},

		async handleAsyncNonBlocking(promise, message, errorMessage) {
			this.progressNonBlocking(message)
			return await this.handleAsyncInternal(promise)
		},

		hideProgress() {
			this.progressShowCounter--// = Math.max(0, this.progressShowCounter - 1)
			console.log("<--- progress: hideProgress, this.progressShowCounter = ", this.progressShowCounter, ", this = ", this, ", this._uid=" + this._uid)
			if(this.progressShowCounter > 0){
				console.error("hideProgress: you are calling 'progress' methods multiple times. Make sure they are only called sequentially to prevent unpredicted results. this._uid=" + this._uid, ", this = ", this)
				// return
			}
			if(this.getAppComponent()){
				this.getAppComponent().isGlobalBlockingProgressShowing = false
			}
			this.isDataLoading = false;
			this.isProgressShowing = false;
			this.$Progress.finish();
		},

		hideProgressFail(errorMessage) {
			this.progressShowCounter = Math.max(0, this.progressShowCounter - 1)

			console.log("<--- progress: hideProgressFail: ", errorMessage, ", this.progressShowCounter = ", this.progressShowCounter, ", this._uid=" + this._uid)

			this.isError = true
			if (errorMessage) {
				this.errorMessage = errorMessage
			}
			this.isDataLoading = false;
			this.isProgressShowing = false;
			this.getAppComponent().isGlobalBlockingProgressShowing = false
			this.getAppComponent().progressText = "Loading..."
			this.$Progress.fail();
		},

		async handleAsyncInternal(promise, options = {}) {
			let defaultValue
			let defaultValueFail
			if(options.defaultValue !== undefined){
				defaultValue = options.defaultValue
			} else if(options.fallback !== undefined){
				defaultValue = options.fallback
			} else if(options.default !== undefined){
				defaultValue = options.default
			}
			if(options.defaultFail !== undefined){
				defaultValueFail = options.defaultFail
			}
			let fallbackValue = defaultValue !== undefined ? defaultValue : null //we generally expect null if no object is returned so subsequent if(res){...} can be executed when the valid object was returned. We generally only need "true" for "delete" operations, which can be accomplished using "default" options
			let fallbackValueFail = defaultValueFail !== undefined ? defaultValueFail : false
			try {
				let res = await this.executePromise(promise)
				// console.log("handleAsyncInternal: res = ", res, ", options = ", options)
				this.hideProgress()
				if(options.onSuccess){
					await options.onSuccess(res)
				}
				return res !== null && res !== undefined ? res : fallbackValue
			} catch (error) {
				this.handleError(error, options.errorMessage)
				this.hideProgressFail()
				if(options.onError){
					await options.onError(error)
				}
				console.log("handleAsyncInternal: options = ", options)
				if (options.throwOnError) {
					throw error
				}
				return fallbackValueFail
			}
		},

		async executePromise(promise) {
			if (promise instanceof Function) {
				promise = promise()
			}
			let res

			if (Array.isArray(promise)) {
				res = await Promise.all(promise)
			} else {
				res = await Promise.resolve(promise)
			}
			return res
		},

		// async $nextTickPromise() {
		// 	return this.$nextTick()
		// 	// return new Promise(resolve => this.$nextTick(() => resolve()))
		// },
		async $nextTickPromise() {
			return new Promise(resolve => {
				this.$nextTick(resolve)
			})
		},

		createRedirectParams(originTitle, route = {}) {
			let {routeName, params, query} = route
			routeName = routeName || this.$route.name
			params = params || this.$route.params
			query = query || this.$route.query
			Object.keys(params).forEach(key => {
				console.log(`createRedirectParams: params[${key}] = `, params[key])
				if(params[key] instanceof BaseObject){
					console.error(`createRedirectParams: parameter is an object which can't be transferred becasue it needs to be properly deserialized which is not currently supported. Removing it. params[${key}] = `, params[key])
					delete params[key]
				}
			})
			let redirectParam = {routeName, params, query, originTitle}
			console.log("createRedirectParams: ", redirectParam)
			let srcParams = asBase64(JSON.stringify(redirectParam))
			return {qworgn: srcParams}
		},

		readRedirectParams() {
			let res
			let qworgn = this.$route.query.qworgn
			if (qworgn) {
				console.log("readRedirectParams: qworgn = ", qworgn)
				try{
					res = JSON.parse(fromBase64(qworgn))
				} catch (error){
					let _ = this.logError(error)
				}
			}
			return res
		},

		async reloadPage(){
			await this.handleAsync(() => this.$router.go())
			// window.location.reload(true)
		},


		async saveRedirectParams(routeName, params, query, sourceTitle) {
			let qworgn = this.createRedirectParams(routeName, params, query, sourceTitle)
			await this.setQuery(qworgn)
		},


		async goBackOrigin(name, params, query){
			if(typeof name === "string"){
				name = {name, params, query}
			}
			return this.redirectWithRedirectParams(name)
		},
		async redirectWithRedirectParams(fallbackOptions){
			let redirectParams = this.readRedirectParams()
			console.log("redirectWithRedirectParams: redirectParams = ", redirectParams, ", fallbackOptions = ", fallbackOptions)
			if(redirectParams){
				let {routeName, params, query} = redirectParams
				await this.go(routeName, params, query)
			} else if(fallbackOptions){
				await this.goOptions(fallbackOptions)
			}
		},
		hasBackRoute(){
			return this.hasOriginRedirectParams()
		},

		hasOriginRedirectParams(){
			return this.readRedirectParams() != null
		},


		async setDeviceClientId(clientId){
			if(!clientId){
				return
			}
			await Promise.all([
				this.setQuery({clientId}),
				this.cookie.clientId.set(clientId)
			])
		},


		onFormValidated(formNameArray, params) {
			console.log("onFormValidated: formNameArray = ", formNameArray)
			return true
		},
		onFormFailed(formNameArray, params, error) {
			console.warn("onFormFailed: formNameArray = ", formNameArray)
			this.toastWarn("Some required fields are not set.")
			return false
		},

		clearFormValidation(formNameParam) {
			let formName = formNameParam || "mainForm";
			// console.log("clearFormValidation: formName = ", formName)
			if(!Array.isArray(formName)){
				formName = [formName]
			}
			formName.map(name => {
				let form = this.findRefByName(name)
				// console.log("form = ", form)
				if(form){
					if(!Array.isArray(form)){
						form = [form]
					}
					form.forEach(form => form.clearValidate())

				} else {
					console.log("clearFormValidation: form not found: ", name)
				}

			});
		},

		findRefByName(name){
			let result = null
			let target = this
			while(!result && target && target.$refs){
				result = target.$refs[name];
				target = target.$parent
			}
			return result;
		},


		handleError(error, message = "Something went wrong. Please try to refresh current tab or contact support.", duration = 3000){
			if(error){
				this.logError(error)
			}
			//599 is ClientFriendlyErrorCode so we want to display it instead
			console.log("error = ", error, ", error.response = ", error ? error.response : null)
			if(error && error.response && error.response.status === 599 && error.response.data && error.response.data.error){
				message = error.response.data.error
			}
			if(error && (error.code === "permission-denied" || error.message && error.message.indexOf("Missing or insufficient permissions") >= 0)){
				message = "You don't have permissions to perform this action."
			}
			this.toastError(message, duration)
			this.hideProgressFail()
		},
		toast(message = "Success.", duration = 4000){
			this.$message({
				showClose: true,
				message,
				duration,
				type: "success"
			})
		},
		toastWarn(message, duration = 2000){
			this.$message({
				showClose: true,
				message,
				duration,
				type: "warning"
			})
		},

		toastInfo(message, duration = 2000){
			this.$message({
				showClose: true,
				message,
				duration,
				type: "info"
			})
		},

		toastError(message, duration = 2000){
			this.$message({
				showClose: true,
				message,
				duration,
				type: "error"
			})
		},


		onBackPressed() {
			if (this.hasOriginRedirectParams()) {
				return this.handleAsync(() => this.goBackOrigin())
			} else {
				this.$emit("onBackPressed")
			}
		},


		priceFormatted(amount, currencyCode){
			return this.currencyFormatted(amount, currencyCode)
		},
		currencyFormatted(amount, currencyCode){
			let currencyItem
			if(currencyCode){
				currencyCode = CurrencyItem.currencyByCode(currencyCode)
			}
			if(!currencyItem){
				currencyItem = this.ownerProfile.currencyItem
			}

			return currencyItem.formatted(amount)
		},

		getAppComponent(){
			let app = this.$root//.$children[0]
			// console.trace("getAppComponent: app = ", app)
			// console.trace("getAppComponent: app.isGlobalBlockingProgressShowing = ", app.isGlobalBlockingProgressShowing)
			return app
		},


	},
	directives: {
		tabindex: {
			bind(el, binding) {
				console.log("v-tabindex: value = ", binding.value)
				el.setAttribute('tabindex', binding.value);
			}
		}
	}
}
</script>

<style lang="scss">

</style>
