<template>
    <FormRow
        class="form-select-row-root"
        :prop="prop"
        :title="title"
        :tooltipText="tooltipText"
		:tooltip-position="tooltipPosition"
        :isFocused="isFocusedData"
        :readonly="readonly"
        v-bind="listenersCustom"
        :content-class="readonly ? '' : 'white-smoke'"
        :disabled="disabled">

        <div v-if="readonly">
            <slot :option="getOptionByValueData" :index="0">
                {{getOptionLabel(getOptionByValueData)}}
            </slot>
        </div>
        <div v-else class="qw-row input-background">
            <el-select v-model="valueData2"
                       ref="selectElem"
                       :placeholder="placeholder"
                       v-bind="listenersCustom"
                       :value-key="valueKey"
                       :name="labelKey"
					   :multiple="multiple"
					   :filterable="filterable"
                       :remote="false"
					   :allow-create="false"
                       :popper-class="dropDownClass + ' ' + dropDownClasses"
                       :filter-method="isRemote ? onFilter : undefined"
                       class="col px-0 qw-select-root"
                       @visible-change="onDropdownVisibilityChanged"
                       @change="notifyValueChanged">
                <div class="relative" :class="{'select-popup-loading': showLoading}">

					<div v-if="showNewOption()" key="create_opt"
						 class="el-select-dropdown__item"
						 @click="onCreateNewItemClicked()">
						<span>{{query}}</span>
						<small v-if="showClickToCreatePrompt" class="link-button ml-4">click to create</small>
					</div>

                    <el-option v-show="true" v-for="(option, index ) of getNotLoadedOptions()" :key="'incoming_opt_' + index"
                               :value="getOptionValue(option)"
                               :label="getOptionLabel(option)"></el-option>


                    <SelectOptionsContainer
                        v-if="!isRemote"
                        :data-array="options"
                        :disabled="disabled"
                        @getOptionLabel="getOptionLabel"
                        @getOptionValue="getOptionValue"
						#default="{option, index}">

                        <slot :option="option" :index="index"></slot>

                    </SelectOptionsContainer>

                    <div v-else>
                        <el-option v-show="showDummyLoadingOption" value="dummy-loading" key="dummy-loading">Loading...</el-option>

                        <PagerListView
                            ref="pagerListLayout"
                            :loadDataOnStart="loadDataOnStart"
                            :addBottomMargin="false"
                            :pager-provider="pagerProvider"
                            @onLoadingStateChanged="onLoadingStateChanged"
                            :scroll-container-selector="scrollContainerSelectorComputed">

                            <template #items-container="{dataArray}">
                                <SelectOptionsContainer
                                    :data-array="dataArray"
                                    :disabled="disabled"
                                    @getOptionLabel="getOptionLabel"
                                    @getOptionValue="getOptionValue"
									#default="{option, index}">

                                    <slot :option="option" :index="index"></slot>

                                </SelectOptionsContainer>
                            </template>
                            <template #empty-view>
                                <slot name="empty-view"></slot>
                            </template>
							<template #empty-view-filter>
								<slot name="empty-view"></slot>
							</template>
                        </PagerListView>
                    </div>
<!--                    <div class="ml-4" v-if="!isLoadingMore && hasMoreItemsToLoad">-->
<!--                        <qw-link-button @click="loadMore">Load more</qw-link-button>-->
<!--                    </div>-->


                    <div v-if="!isLoadingMore" class="">
                        <slot name="extra-item" ></slot>
                    </div>


                    <LoadingIndicator :is-loading="showLoading"/>

                </div>

				<template #empty>
					<div>
						<slot name="empty">
							<div class="text-center p-2">No data</div>
						</slot>
					</div>
				</template>

            </el-select>
            <slot name="after"/>
        </div>


    </FormRow>

</template>

<script>
import BaseFormRow from "@/core/components/Form/Base/BaseFormRow.vue"
import FormRow from "@/core/components/Form/Base/FormRow.vue";
import LoadingIndicator from "@/core/components/LoadingIndicator.vue";
import {isObject, uniqueBy, uuidv4} from "@/utils/helpers";
import PagerListLayout from "@/core/components/PagerListLayout.vue";
import PagerLocal from "@shared/PagerLocal";
import SelectOptionsContainer from "@/core/components/Form/SelectOptionsContainer.vue";
import {logError} from "@/utils/helpers";

import debounce from "@/core/recoveryCoachingApp/coach/clients/ClientDetails/Genogram/GenogramEditor/utils/debounce";
import PagerListView from "@/core/components/PagerListView.vue";

export default {
    name: "FormSelectRow",
    components: {PagerListView, SelectOptionsContainer, PagerListLayout, LoadingIndicator, FormRow},
    extends: BaseFormRow,
    props: {
		showClickToCreatePrompt: Boolean,
        options: Array,
		filterable: Boolean,
		allowCreate: Boolean,
        optionsPagerProvider: Function,
        labelKey: {String, default: "label"},
        valueKey: {String, default: "value"},
        returnOptionValue: Boolean,
        disabledKey: String,
        placeholder: {String, default: "Select"},
        showLoading: {Boolean, default: false},
        labelTransform: Function,
        loadDataOnStart: Boolean,
        dropDownClasses: String,
		multiple: Boolean,
    },

    watch: {
        options: {
            immediate: true,
            handler() {
                // this.ensureDefaultValue()
                this.notifyIncomingValueChanged()
                // this.valueData2 = this.valueData

                // console.log("options watch: " + JSON.stringify(this.options))
            }
        },
    },

    data() {
        return {
			query: "",
			canShowCreateOption: false,
            optionsFromIncomingValue: [],
            pager: null,
            showDummyLoadingOption: true,
            hasMoreItemsToLoad: true,
            isLoadingMore: false,
            dropDownClass: 'class-' + uuidv4(),
            isDropdownVisible: false,
        }
    },

    created(){
        this.onFilter = debounce(this.onFilter, 500)
    },

    mounted() {
        // this.loadPager()
    },

    methods: {


        blur(){
            this.$refs.selectElem.blur()
        },

		focus(){
			this.$refs.selectElem.focus()
		},


        // getOptions(remoteDataArray){
        // 	return this.isRemote ? remoteDataArray : this.options
        // },

        async onFilter(query){
			this.query = query
			this.canShowCreateOption = true
            return this.loadPager(query)
        },

        async getOptionsPager(query) {
            return this.optionsPagerProvider ? this.optionsPagerProvider(query) : new PagerLocal(this.options)
        },

		onSelectInputChange(){
			console.log("onSelectInputChange")
		},

        async onDropdownVisibilityChanged(visible) {
            console.log("onDropdownVisibilityChanged: visible = ", visible)
            this.isDropdownVisible = visible
            if (visible && this.isRemote) {
                this.showDummyLoadingOption = true
				this.$refs.pagerListLayout.forceShowLoadingIndicator(true)//we need to trigger it here manually, because this.onFilter() is debounced and will be executed with a delay. We don't call this.loadPager here because it will be called twice - from visibilityChange and from onFilter. Other approaches are viable too.
                await this.onFilter()
                this.showDummyLoadingOption = false
            }

			// let shouldUpdate = false
			// let options = this.$refs.selectElem.options
			// console.log("options = ", options)
			// let i = 0
			// while(i < options.length){
			// 	let option = options[i]
			// 	if(option.created){
			// 		option.visible = false//$el.remove(option.$el)
			// 		this.$refs.selectElem.filteredOptionsCount--
			// 		// this.$refs.selectElem.options.splice(i, 1)
			// 		// shouldUpdate = true
			// 	}
			// 	i++
			// // }
			// }
			// if(shouldUpdate){
			// 	this.$forceUpdate()
			// }
            // this.valueData2 = ""
            // await this.$nextTickPromise()
            // this.valueData2 = this.valueData
        },

        async loadPager(query) {
            this.pager = await this.getOptionsPager(query)
            console.log("FormSelectRow: getPager = ", this.pager)
            await this.loadPagerData()
        },

        async loadPagerData() {
			if(this.$refs.pagerListLayout) {
				await this.$refs.pagerListLayout.loadData()
			}
        },

        notifyElementUpdated(element){
            if(this.$refs.pagerListLayout){
                this.$refs.pagerListLayout.notifyElementUpdated(element)
            }
        },
        notifyElementDeleted(element){
            if(this.$refs.pagerListLayout){
                this.$refs.pagerListLayout.notifyElementDeleted(element)
            }
        },
        notifyElementAdded(element){
            if(this.$refs.pagerListLayout){
                this.$refs.pagerListLayout.notifyElementAdded(element)
            }
        },




        onLoadingStateChanged(isLoadingMore, hasMoreItems){
			console.log("FormSelectRow: onLoadingStateChanged = ", ...arguments)
            this.isLoadingMore = isLoadingMore
            this.hasMoreItemsToLoad = hasMoreItems
        },


        async pagerProvider() {
            console.log("FormSelectRow: pagerProvider = ", this.pager)
            if (!this.pager) {
                this.pager = await this.getOptionsPager()
            }
            return this.pager
        },

        loadMore() {
			if(this.$refs.pagerListLayout){
				this.$refs.pagerListLayout.onLoadMore()
			}
        },

        // ensureDefaultValue() {
        // 	// if(this.valueData){
        // 	// 	this.options.unshiftUnique(this.valueData, (l, r) => l.id === r.id)
        // 	// }
        // },


        onCurrentLabelChanged(...args) {
            console.log("onCurrentLabelChanged: args = ", ...args)
        },

        onValueChanged(value) {
        	// console.log("onValueChanged1: value = ", value)
            // console.log("onValueChanged1: this.optionsFromIncomingValue = ", this.optionsFromIncomingValue)
            value = Array.isArray(value) ? value : [value]
            value = value.filter(item => item != null)
            this.optionsFromIncomingValue.push(...value)
            this.optionsFromIncomingValue = uniqueBy(this.optionsFromIncomingValue, v => v.id)
            // console.log("onValueChanged1: this.optionsFromIncomingValue = ", this.optionsFromIncomingValue)
        	// let optionValue = this.getOptionValue(value)
        	// this.selectedLabel = this.findOptionByValue(optionValue) || this.selectedLabel
        	// this.ensureDefaultValue()
        },


        findOptionByLabel(label) {
            let res = this.getAllAvailableOptions().find(option => this.getOptionLabel(option) === label)
            console.log("findOptionByLabel: label = ", label, ", res = ", res)
            return res
        },
        findOptionByValue(value) {
            let res = this.getAllAvailableOptions().find(option => this.getOptionValue(option) === value)
            // console.log("findOptionByValue: value = ", value, ", res = ", res, ", options = " + JSON.stringify(this.getAllAvailableOptions()))
            return res
        },

        getOptionLabel(option) {
            // console.log("getOptionLabel2: ", option)
            if(Array.isArray(option)){
                return option.map(opt => this.getOptionLabel(opt))
            }
            let label = isObject(option) ? option[this.labelKey] : option
            if (this.labelTransform) {
                label = this.labelTransform(label, option)
            }
            // console.log("getOptionLabel2: label = ", label)
            return label || ""
        },

        getOptionValue(option) {
            let val = isObject(option) ? option[this.valueKey] : option
            // console.log("getOptionValue: val = ", val, ", option = ", option)
            return val
        },

        // _notifyValueChangedLocal(option){
        // 	let val = this.formatOutInternal(option)
        // 	console.log("_notifyValueChangedLocal: val = ", val, ", option = ", option)
        // 	this.valueData = val
        // 	this.notifyValueChanged()
        // },

        formatInInternal(value) {
            // console.log("formatInInternal: ", value)
            if(Array.isArray(value)){
                return value.map(val => this.formatInInternal(val))
            }
            if (this.returnOptionValue) {
                value = this.findOptionByValue(value)
            }
            if (this.formatDataInProp) {
                return this.formatDataInProp(value)
            }
            return this.formatDataIn(value)
        },

        formatOutInternal(value) {
            // console.log("formatOutInternal: ", value)
            if(Array.isArray(value)){
                return value.map(val => this.formatOutInternal(val))
            }
            if (this.returnOptionValue) {
                value = this.getOptionValue(value)
            }
            if (this.formatDataOutProp) {
                return this.formatDataOutProp(value)
            }
            return this.formatDataOut(value)
        },



        getDataLabels(dataValue){
            let availableOptions = this.getAllAvailableOptions()
            // console.log("getDataLabels: availableOptions = ", availableOptions)
            // console.log("getDataLabels: dataValue = ", dataValue)
            let res = []
            let returnArray = true
            if(!Array.isArray(dataValue)){
                dataValue = [dataValue]
                returnArray = false
            }

            for(let dataItem of dataValue){
                let value = this.getOptionValue(dataItem)
                let containsItem = false
                for(let option of availableOptions){
                    if(this.getOptionValue(option) === value){
                        containsItem = true
                        break;
                    }
                }
                // if(containsItem){
                //     res.push(dataItem)
                // } else {
                    res.push(this.getOptionValue(dataItem))
                // }
            }
            let result = returnArray ? res : res[0]

            // console.log("getDataLabels: res = ", res)
            // console.log("getDataLabels: result = ", result)


            return result

        },

        getAllAvailableOptions(){
            let options = this.getLoadedOptions()
            options.push(...this.optionsFromIncomingValue)
            // console.log("getAllAvailableOptions: options = ", options)

            return options

        },

        getLoadedOptions(){
            let options = []

            try{
                options.push(...(this.options || []))
                if(this.$refs.pagerListLayout){
                    options.push(...this.$refs.pagerListLayout.dataArrayInner)
                }
            } catch (error){
                logError(error)
            }


            // console.log("getNotLoadedOptions getLoadedOptions: this.$refs.pagerListLayout = ", this.$refs.pagerListLayout)
            // console.log("getNotLoadedOptions getLoadedOptions: options = ", options)

            return options

        },

		async onCreateNewItemClicked(){
			this.canShowCreateOption = false
			this.valueData = await this.emitPromise("createNewItem", this.query)
			this.$refs.selectElem.blur()
			this.notifyValueChanged()
		},

		showNewOption() {
			// showCreateOption && query
			let availableOptions = this.getLoadedOptions()
			let hasExistingOption = availableOptions.some(option => this.getOptionLabel(option) === this.query);
			return this.filterable && this.allowCreate && this.canShowCreateOption && !!this.query && !hasExistingOption;
		},


		getNotLoadedOptions(){
            let availableOptions = this.getLoadedOptions()
            // console.log("getNotLoadedOptions: availableOptions = ", availableOptions)
            let res = []
            let dataValue = this.optionsFromIncomingValue//Array.isArray(this.valueData) ? this.valueData : [this.valueData]
            // console.log("getNotLoadedOptions: dataValue = ", dataValue)


            for(let dataItem of dataValue){
                let value = this.getOptionValue(dataItem)
                let containsItem = false
                for(let option of availableOptions){
                    if(this.getOptionValue(option) === value){
                        containsItem = true
                        break;
                    }
                }
                if(!containsItem && (this.query === "" || this.getOptionLabel(dataItem).startsWith(this.query))){
                    res.push(dataItem)
                }
            }
            // this.optionsFromIncomingValue = res
            // console.log("getNotLoadedOptions: res = ", res)

            return res

        },

    },

    computed: {




        getOptionByValueData() {
            let index = this.getAllAvailableOptions().findIndex(option => option.value === this.valueData.value)
            if (index >= 0) {
                return this.getAllAvailableOptions()[index]
            }
            return null
        },

        listenersCustom() {
            return {
                ...this.attrsListenersOnly,
                onChange: value => {
                    console.log("FormSelectRow: on changed = ", value)
                },
                'onChange:modelValue': value => {
                    console.log("FormSelectRow: on input = ", value)
                }
            }
        },

        scrollContainerSelectorComputed() {
            let selector = "." + this.dropDownClass + " .el-select-dropdown__wrap"
            console.log("scrollContainerSelectorComputed = '" + selector + "'")
            return selector
        },

        // selectedLabel: {
        // 	get: function () {
        // 		return this.valueData//this.getOptionLabel(this.valueData)
        // 	},
        // 	set: function (val) {
        // 		this.valueData = val//this.findOptionByValue(val)
        // 		this.notifyValueChanged()
        // 	}
        // },

        isRemote() {
            return this.optionsPagerProvider != null
        },

        valueData2: {
            get: function () {
                // let option = this.valueData
                // let res = option
                // if(isObject(option)){
                // 	let value = this.getOptionValue(option)
                // 	let optionFromArray = this.findOptionByValue(value)
                // 	if(!optionFromArray){
                // 		res = this.getOptionLabel(option) + " - label"
                // 	}
                // }
                // // this.$nextTick(() => this.reloadingKey++)
                // // setTimeout(() => this.$refs.selectElem.$forceUpdate(), 500)
                // console.log("valueData2: ", res)
                // return res
                // if(!this.dataContainsInAvailableOptions(this.valueData)){
                //     return this.getOptionLabel(this.valueData)
                // }
                // console.log("getOptionLabel2 get valueData2: this.valueData = ", this.valueData)
				let res
                if(Array.isArray(this.valueData)){
                    res = this.valueData.map(item => this.getOptionValue(item))//
                } else {
					res = this.getOptionValue(this.valueData)
                }
				// console.log("getOptionLabel2 get valueData2: res = ", res)
				return res
                // return this.valueData//this.getDataLabels(this.valueData)
            },

            set: function (val) {

                if(Array.isArray(val)){
                    this.valueData = val.map(v => this.findOptionByValue(v) || v)
                } else {
                    this.valueData = this.findOptionByValue(val) || val
                }
                // console.log("setOptionLabel2 valueData2 selected = ", val, ", this.valueData = ", this.valueData)
                // this.notifyValueChanged()
            }
        }
    }
}
</script>

<style lang="scss">
.select-popup-loading {
    min-height: 100px;
}

.form-select-row-root {
    .el-select .el-input .el-select__caret {
        color: black;
    }

}

.el-select-dropdown__item {
    //height: 350px;
}
.el-select-dropdown__item.hover {
    background-color: inherit;
}
.el-select-dropdown__item.hover:hover {
    background-color: #F7F7F7;
}

</style>
