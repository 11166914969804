/**
 * Created by DemonRay on 2019/3/28.
 */
import utils from '../../utils'
import {isMobileScreen, isTouchDevice} from "@/core/screenHelper";

let defaults = {
  container: false,
  commands: [
	// { command: 'toggleLeftPanel', icon: 'icon-menu', disabled: false, title: 'Show/hide symbols panel', hideOnBigScreen: true},
    { command: 'undo', icon: 'icon-ccw', disabled: true, title: 'Undo' },
    { command: 'redo', icon: 'icon-cw', disabled: true, title: 'Redo' },
    { command: 'zoomin', icon: 'icon-zoom-in', disabled: false, title: 'Zoom In', separator: true },
    { command: 'zoomout', icon: 'icon-zoom-out', disabled: false, title: 'Zoom Out' },
    { command: 'boxselect', icon: 'icon-marquee', disabled: false, title: 'Selection Mode', selected: false, hideOnSmallScreen: true  },
    { command: 'copy', icon: 'icon-docs', disabled: true, title: 'Copy Selected', separator: true },
    { command: 'paste', icon: 'icon-paste', disabled: true, title: 'Paste Selected' },
    { command: 'delete', icon: 'icon-trash', disabled: true, title: 'Delete Selected'},
    // { command: 'leveldown', icon: 'icon-arrow-to-bottom', disabled: true, title: '层级后置' },
    // { command: 'levelup', icon: 'icon-top-arrow-from-top', disabled: true, title: '层级前置' },
    // { command: 'line-straight', icon: 'icon-Line-Tool', disabled: false, title: '直线', selected: false, separator: true },
    // { command: 'line-taxi', icon: 'icon-gongzuoliuchengtu', disabled: false, title: '折线', selected: false },
    // { command: 'line-bezier', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-family-relationship', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-harmony1', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-harmony2', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-harmony3', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-harmony4', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-harmony5', icon: 'icon-Bezier-', disabled: false, title: '曲线', selected: false },
    // { command: 'line-hostile1', icon: 'icon-Line-Tool', disabled: false, title: '曲线', selected: false },
    // { command: 'line-hostile2', icon: 'icon-Line-Tool', disabled: false, title: '曲线', selected: false },
    // { command: 'line-hostile3', icon: 'icon-Line-Tool', disabled: false, title: '曲线', selected: false },
    // { command: 'line-hostile4', icon: 'icon-Line-Tool', disabled: false, title: '曲线', selected: false },
    // { command: 'line-hostile5', icon: 'icon-Line-Tool', disabled: false, title: '曲线', selected: false },
    // { command: 'gridon', icon: 'icon-grid', disabled: false, title: 'Grid', selected: false, separator: true },
    { command: 'fit', icon: 'icon-resize-small-alt', disabled: false, title: 'Fit Genogram Into Screen', separator: true, hideOnSmallScreen: true },
    { command: 'save', icon: 'icon-download', disabled: false, title: 'Export to JPG', separator: true }
  ]
}
class Toolbar {
  constructor (cy, params) {
    this.cy = cy
    this._init(params)
    this._listeners = {}
    this._initEvents()
  }

  _init (params) {
    this._options = Object.assign({}, defaults, params)
    if (Array.isArray(this._options.toolbar)) {
      this._options.commands = this._options.commands.filter(item => this._options.toolbar.indexOf(item.command) > -1)
    }

	  this._options.commands = this._options.commands.filter(item => {
		  let canShow = isMobileScreen() ? !item.hideOnSmallScreen : !item.hideOnBigScreen
		  console.log("CyToolbar._init: item = ", item, ", canShow = ", canShow)
		  return canShow
	  })


	  this._initShapePanel()
  }

  removeCommand(command){
	  this._options.commands = this._options.commands.filter(item => item.command !== command)
	  let commandToDelete = this._panel.querySelectorAll(`[data-command='${command}']`);
	  if(commandToDelete && commandToDelete[0]){
		  commandToDelete[0].remove()
	  }
	  // console.log("commandToDelete = ", commandToDelete)
  }

  _initEvents () {
    this._listeners.command = (e) => {
      let command = e.target.getAttribute('data-command')
      if (!command) { return }
      let commandOpt = this._options.commands.find(it => it.command === command)
      if (['boxselect', 'gridon'].indexOf(command) > -1) {
        this.rerender(command, { selected: !commandOpt.selected })
      }

      if (commandOpt) {
        this.cy.trigger('cyeditor.toolbar-command', commandOpt)
      }
    }
    this._panel.addEventListener('click', this._listeners.command)
    this._listeners.select = this._selectChange.bind(this)
    this.cy.on('select unselect', this._listeners.select)
  }

  _selectChange () {
    let selected = this.cy.$(':selected')
	  console.log("CyToolbar._selectChange: selected = ", selected)
    if (selected && selected.length !== this._last_selected_length) {
      let hasSelected = selected.length > 0
		let hasNonDeletable = selected.filter(it => it.data('nonDeletable')).length > 0
      this._options.commands.forEach(item => {
        if ([ 'delete', 'copy', 'leveldown', 'levelup' ].indexOf(item.command) > -1) {
          item.disabled = !hasSelected
        }
		if (hasNonDeletable && selected.length === 1 && item.command === 'delete') {
		  item.disabled = true
		}
      })
      this._panelHtml()
    }
    this._last_selected_length = selected
  }

  _initShapePanel () {
    let { _options } = this
    if (_options.container) {
      if (typeof _options.container === 'string') {
        this._panel = utils.query(_options.container)[ 0 ]
      } else if (utils.isNode(_options.container)) {
        this._panel = _options.container
      }
      if (!this._panel) {
        console.error('There is no any element matching your container')
        return
      }
    } else {
      this._panel = document.createElement('div')
      document.body.appendChild(this._panel)
    }
    this._panelHtml()
  }

  _panelHtml () {
    let icons = ''
    this._options.commands.forEach(({ command, title, icon, disabled, selected, separator }) => {
      let cls = `${icon} ${disabled ? 'disable' : ''} ${selected === true ? 'selected' : ''}`
      if (separator) icons += '<span class="separator"></span>'
      icons += `<i data-command="${command}" class="fontello command ${cls} ${isTouchDevice() ? 'touch-device' : ''}" data-title="${title}"></i>`
    })
    this._panel.innerHTML = icons
  }


  rerender (cmd, options = {}) {
    let cmdItem = this._options.commands.find(it => it.command === cmd)
	  console.log("rerender: ", cmd, ", options = ", options, ", cmdItem = ", cmdItem)
	  if(!cmdItem){
		  return
	  }
    let opt = Object.assign(cmdItem, options)
    if (opt) {
      let iconEls = utils.query(`i[data-command=${cmd}]`)
      iconEls.forEach(item => {
        if (item.parentNode === this._panel) {
          if (opt.icon) {
            item.className = `fontello command ${opt.icon}`
          }
          if (opt.disabled) {
            utils.addClass(item, 'disable')
          } else {
            utils.removeClass(item, 'disable')
          }
          if (opt.selected) {
            utils.addClass(item, 'selected')
          } else {
            utils.removeClass(item, 'selected')
          }
        }
      })
    }
  }
}

export default (cytoscape) => {
  if (!cytoscape) { return }

  cytoscape('core', 'toolbar', function (options) {
    return new Toolbar(this, options)
  })
}
