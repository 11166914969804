<template>
	<FormRow
		:prop="prop"
		:title="title"
		:labelMinWidth="labelMinWidth"
		:isVertical="isVertical"
		v-bind="$attrs"
		:tooltipText="tooltipText"
		:tooltip-position="tooltipPosition">

		<div class="qw-row">

		<div class="col px-0" >
			<span v-if="titlePrefix" class="pr-2">{{ titlePrefix }}</span>
			<span v-if="range && !readonly">
<!--				<qw-autocomplete-->
<!--					:style="{'width': (width + 10) + 'px'}"-->
<!--					style="display: inline-block"-->
<!--					:readonly="false"-->
<!--					:placeholder="placeholder"-->
<!--					:suggestions="rangeOptionsComputed"-->
<!--					@change="notifyValueChanged"-->
<!--					v-model="valueData"/>-->
				<qw-select
					ref="select"
					:style="{'width': width}"
					style="display: inline-block"
					:filterable="rangeEditable"
					:allow-create="rangeEditable"
					:default-first-option="true"
					:readonly="false"
					:placeholder="placeholder"
					:options="rangeOptionsComputed"
					@change="notifyValueChanged"
					v-model="valueData"/>
			</span>
			<qw-input type="number"
					  v-else
					  @change="notifyValueChanged"
					  @blur="notifyValueChangedImmediate"
					  @keypress.enter.native="notifyValueChangedImmediate"
					  @focus="onFocus()"
					  class="mr-2 input-number"
					  :class="{'white-smoke': !readonly}"
					  :readonly="readonly"
					  :style="{'width': width}"
					  style="display: inline-block"
                      :input-debounce-delay="inputDebounceDelay"
					  :placeholder="placeholder"
					  v-model="valueData"/>
			<div style="display: inline-block">
				<slot name="postfix"><span v-if="titlePostfix" class="pl-2">{{ titlePostfix }}</span></slot>
			</div>
		</div>
		</div>

	</FormRow>

</template>

<script>

import BaseFormRow from "@/core/components/Form/Base/BaseFormRow.vue"
import FormRow from "@/core/components/Form/Base/FormRow.vue";
import QwInput from "@/core/components/Controls/QwInput.vue";

export default {
	name: "FormNumberRow",
	components: {QwInput, FormRow},
	extends: BaseFormRow,
	props: {
		tooltipPosition: String,
		placeholder: {String, default: "type"},
        inputDebounceDelay: Number,
		range: Array,
		rangeEditable: Boolean,
		labelMinWidth: {Number, default: 0},
		isVertical: Boolean,
		width: {String, default: "3.75rem"},
		convertToNumber: {Boolean, default: true}
	},



	mounted(){
		// console.log("FormNumberRow: readonly = ", this.readonly)
	},
	methods: {
		onFocus() {
			this.$emit("focus")
		},

		formatDataOut(data){
			let res = data && this.convertToNumber ? Number(data) : data
			console.log("FormNumberRow formatDataOut: data = ", data, ", res = ", res)
			return res
		},

		onSelectBlur(){
			console.log("onSelectBlur")
			// this.$refs.select.$refs.select.onInputChange()
			this.$refs.select.$refs.select.handleQueryChange("hey")
		}

	},

	computed: {
		rangeOptionsComputed() {
			let options = []
			let start = Math.min(this.range[0], this.range[1])
			let end = Math.max(this.range[0], this.range[1])
			for(start; start <= end; ++start){
				options.push(start)
			}
			return options
		}
	},
}
</script>

<style>

</style>
