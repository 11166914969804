import DocSignTemplateItem from "@/core/modules/DocSign/data/DocSignTemplateItem";
import {DocSignRequestStatus} from "@/core/modules/DocSign/data/DocSignDocument/DocSignRequestStatus";
import {FilterableProperty} from "@shared/Filterable/PropertyDecorators";
import dayjs from "dayjs";
import {daysDifference, daysDifferenceFormatted} from "@/utils/helpers";
import {AssignToOptions} from "@/core/modules/DocSign/data/DocSignAssignToData";

export const MAX_REMINDER_RETRIES = 3
// export const REMINDER_RETRIES_INTERVAL_MS = 3

export default class DocSignDocument extends DocSignTemplateItem{

	@FilterableProperty({includeFullWord: true})
	signStatus: DocSignRequestStatus = DocSignRequestStatus.PENDING
	currentSigner: string = AssignToOptions.CLIENT

	clientId: string = null
	coachId: string = null

	packageId: string = null
	packageTitle: string = null
	isGroupEntry: Boolean = null

	@FilterableProperty()
	clientName: string = null

	@FilterableProperty()
	clientEmail: string = null

	dateSignedUtc: number = null

	parentTemplateId: string = null
	lastSavedParentTemplateId: string = null

	journalEntryId: string = null
	lastResentTs: number = null
	resendAttempts: number = 0
	nextReminderTs: number = null

	reminderTaskId: string = null

	constructor() {
		super();
	}

	getCurrentSchemaVersion() {
		return 3
	}

	assignClientFromClientProfile(clientProfile){
		this.clientId = clientProfile.id
		this.clientName = clientProfile.getFullName()
		this.clientEmail = clientProfile.email
		this.addSharedWithById(clientProfile.id)
	}
	assignFromPackageItem(packageItem){
		if(packageItem && packageItem.isGroupPackage()){
			this.packageId = packageItem.id
			this.packageTitle = packageItem.title
			this.isGroupEntry = true
			this.addSharedWithById(packageItem.id)
		}
	}

	setStatusSigned(){
		this.signStatus = DocSignRequestStatus.SIGNED
	}

	isSignPending(){
		return this.signStatus === DocSignRequestStatus.PENDING
	}
	isSignCompleted(){
		return this.signStatus === DocSignRequestStatus.SIGNED
	}

	dateSentOnFormatted(){
		return this.createdAtFormatted()
	}
	sentDaysAgo(){
		return daysDifference(this.createdAt)
	}
	sentDaysAgoFormatted(){
		return daysDifferenceFormatted(this.createdAt)
	}
	isSigned(){
		return this.isSignCompleted()
	}


	dateSentLabelFormatted(){
		if(Math.abs(this.sentDaysAgo()) < 2){
			return `Sent ${this.sentDaysAgoFormatted()}`
		}
		if(Math.abs(this.sentDaysAgo()) > 30){
			return `Sent on ${this.dateSentOnFormatted()}`
		}

		return `Sent on ${this.dateSentOnFormatted()} (${this.sentDaysAgoFormatted()})`
	}

	setCurrentSignerCoach(){
		this.currentSigner = AssignToOptions.COACH
		this.resetResendAttempts()
	}

	setCurrentSignerClient(){
		this.currentSigner = AssignToOptions.CLIENT
		this.resetResendAttempts()
	}

	canSendMoreReminders(maxNumber){
		return this.resendAttempts < maxNumber
	}

	isCurrentSignerCoach(){
		return this.currentSigner === AssignToOptions.COACH
	}

	isCurrentSignerClient(){
		return this.currentSigner === AssignToOptions.CLIENT
	}

	resetResendAttempts(){
		this.resendAttempts = 0
		this.lastResentTs = null
	}

	onReminderResent(){
		this.resendAttempts++
		this.lastResentTs = dayjs().valueOf()
	}

	onRemindersCompleted(){
		this.nextReminderTs = null
	}


	dateSignedFormatted(){
		return this.dateSignedUtc ? dayjs(this.dateSignedUtc).format("MMM DD, YYYY") : ""
	}

	signStatusLabel(isClient){
		let label = "Not set"
		switch (this.signStatus) {
			case DocSignRequestStatus.PENDING:
				if(isClient){
					label = "Awaiting my signature"
				} else {
					label = "Sent for signature"
				}
				break;
			case DocSignRequestStatus.SIGNED:
				label = "Signed"
				break;
			case DocSignRequestStatus.DECLINED:
				label = "Declined"
				break;
		}
		return label
	}

	signStatusClass(){
		let label = "not-set"
		switch (this.signStatus) {
			case DocSignRequestStatus.PENDING:
				label = "badge-warning"
				break;
			case DocSignRequestStatus.SIGNED:
				label = "badge-success"
				break;
			case DocSignRequestStatus.DECLINED:
				label = "badge-danger"
				break;
		}
		return label
	}


	migrateSchema(oldVersion, newVersion) {
		super.migrateSchema(oldVersion, newVersion)

		if(oldVersion < 2){
			if(this.clientProfileId && !this.clientId){
				this.clientId = this.clientProfileId
			}
		}
		if(oldVersion < 3){
			//this migration was performed in the backend under migrateTeamFeature migration
		}

	}
}
