/**
 * Created by DemonRay on 2019/3/22.
 */
import utils from '../../utils'
import {
	nodeTypeToImage
} from "@/core/recoveryCoachingApp/coach/clients/ClientDetails/Genogram/GenogramEditor/defaults/node-types";
import {isMobileScreen, isTouchDevice} from "@/core/screenHelper";

const defaults = {
	container: false,
	addWhenDrop: true,
	nodeTypes: []
}

class DragAddNodes {
	constructor(cy, params) {
		this.cy = cy
		this._options = Object.assign({}, defaults, params)
		this._options.nodeTypes.forEach(item => {
			item.width = item.width || 76
			item.height = item.height || 76
			item.category = item.category || 'other'
		})

		if (this._options.nodeTypes.length < 1) return
		this._initShapePanel()
		this._initShapeItems()
		if (!params.readonly) {
			this._initEvents()
		}
	}

	_initShapeItems() {
		let shapes = this._options.nodeTypes.filter(item => !item.isExcluded)
		shapes.forEach(item => {
			item._id = utils.guid()
		})
		let categorys = {}
		let other = []
		shapes.forEach(item => {
			if (item.category) {
				if (categorys[item.category]) {
					categorys[item.category].push(item)
				} else {
					categorys[item.category] = [item]
				}
			} else {
				other.push(item)
			}
		})
		if (other.length) {
			categorys.other = other
		}
		let readonlyClass = this._options.readonly ? "readonly" : ""
		let fontSizeClass = "font-size-3"//!isMobileScreen() ? "font-size-3" : "font-size-2"
		let tooltipText = "- To add a template or symbol, tap it below, then tap on the canvas. <br/><br/>- To add a connection, tap a person, then drag'n'drop the red dot onto another person or marriage line."
		// let tooltipText = "Tap symbol, then tap on the canvas.<br/>Switch to landscape or open on desktop for better experience"
		// if(!isTouchDevice()){
		// 	 tooltipText = "Drag'n'drop symbols to the canvas."
		// }
		let categoryDom = Object.keys(categorys).map(item => {
			let shapeItems = categorys[item].map(data => {
				return `
<div data-type="${data.type}" class="text-center col-6 px-0 mb-4">
	<img src="${data.src || nodeTypeToImage[data.type]}"  alt="${data.label}" class="shape-item" draggable="true" data-id="${data._id}" />
	<div class="text-center bold">${data.label}</div>
</div>
`
			}).join('')
			return `<div class="category" style="margin-bottom: 15px;">

                  <div class="title">${item}</div>
                  <div class="shapes qw-row ${readonlyClass}">${shapeItems}</div>
                </div>`
		}).join('')


		let instructionsHeader = readonlyClass ?
			`<div class="p-3">View only mode</div>` :
			`<div id="genogramInstructions" class="p-3 ${fontSizeClass}">${tooltipText}</div>`


		this._shapePanel.innerHTML =
		`<div style="margin-bottom: 150px;">
			${instructionsHeader}
			${categoryDom}
		</div>`
	}

	_initEvents() {
		let rightContainers = this.cy.container()
		let handler = (e) => {
			e.preventDefault()
		}

		let dataTransfer = new DataTransfer()
		utils.query('.shape-item').forEach(item => {
			['dragstart', 'click'].forEach(event => {
				item.addEventListener(event, (e) => {
					console.log("addEles start: e = ", e);
					dataTransfer = new DataTransfer();
					let data = e.dataTransfer || dataTransfer
					data.setData('id', e.target.getAttribute('data-id'))
					this.cy.trigger('cyeditor.addnodestart', e, data)
				})
			})
		});

		['drop', 'click', 'touchstart'].forEach(event => {
			// console
			rightContainers.addEventListener(event, (e) => {
				e.preventDefault();//required for Firefox
				console.log("addEles end: e = ", e);
				let data = (e.dataTransfer || dataTransfer)
				if(!data) return
				let shapeId = data.getData('id')
				dataTransfer = null
				let pos = e.target.compareDocumentPosition(rightContainers)
				console.log("addEles end: pos = ", pos);
				if (pos === 10) {
					let rect
					if(e.targetTouches && e.targetTouches[0]){
						let touch = e.targetTouches[0]
						let {x, y} = touch.target.getBoundingClientRect()
						rect = {x: touch.clientX - x , y: touch.clientY - y}
					} else {
						rect = {x: e.offsetX, y: e.offsetY}
					}
					if (shapeId) {
						const shape = this._options.nodeTypes.find(item => item._id === shapeId)
						console.log("addEles end: shape = ", shape)
						this._addNodeToCy(shape, rect)
					}
				}
			})
		})


		rightContainers.addEventListener('dragenter', handler)
		rightContainers.addEventListener('dragover', handler)
	}

	_addNodeToCy(nodeData, rect) {
		let {type = null, width, height, bg, opacity, border, name = '', points, buildIn = false, src} = nodeData
		let {data = {}, ...scratchData} = nodeData
		let dataLocal = {id: '' + new Date().getTime(), type, name, label: name, width, height, ...data}
		if(bg){
			dataLocal.bg = bg
		}
		if(opacity){
			dataLocal.opacity = opacity
		}
		if(border){
			dataLocal.border = border
		}
		if (!buildIn) {
			dataLocal.image = src
		}
		let node = {
			group: 'nodes',
			data: dataLocal,
			scratch: scratchData, //non-serilizable data that we use to store node level state, like predefined image
			position: rect
		}
		if (points) {
			node.data.points = points
		}
		console.log("addEles: node = ", node)
		console.log("addEles: this._options.addWhenDrop = ", this._options.addWhenDrop)
		if (this._options.addWhenDrop) {
			this.cy.trigger('cyeditor.addnode', node)
		}
	}

	_initShapePanel() {
		let {_options} = this
		if (_options.container) {
			if (typeof _options.container === 'string') {
				this._shapePanel = utils.query(_options.container)[0]
			} else if (utils.isNode(_options.container)) {
				this._shapePanel = _options.container
			}
			if (!this._shapePanel) {
				console.error('There is no any element matching your container')
			}
		} else {
			this._shapePanel = document.createElement('div')
			document.body.appendChild(this._shapePanel)
		}
	}
}

export default (cytoscape) => {
	if (!cytoscape) {
		return
	}

	cytoscape('core', 'dragAddNodes', function (params) {
		return new DragAddNodes(this, params)
	})
}
