import BaseObject from "@shared/BaseObject";
import {FileUploadStatus} from "@/core/components/File/FileUploadProgress/FileUploadStatus";

export default class UploadInfoItem extends BaseObject{

	progress: number = 0
	status: FileUploadStatus = FileUploadStatus.PENDING
	errorMessage: string = null
	uploadTask = null


	constructor() {
		super();
	}

	isPending(){
		return this.status === FileUploadStatus.PENDING
	}

	isCancelled(){
		return this.status === FileUploadStatus.CANCELLED
	}

	isCompleted(){
		return this.status === FileUploadStatus.COMPLETED
	}

	isCompletedFinalizing(){
		return this.status === FileUploadStatus.COMPLETED_FINALIZING
	}

	isError(){
		return this.status === FileUploadStatus.ERROR
	}

	isInProgress() {
		return this.status === FileUploadStatus.IN_PROGRESS
			|| this.isCompletedFinalizing()
	}

	isPaused(){
		return this.status === FileUploadStatus.PAUSED
	}

	isDownloadable(){
		return !this.isCompleted() && !this.isCancelled() && !this.isError()
	}

	setPending(){
		this.status = FileUploadStatus.PENDING
		this.setProgress(0)
	}

	setCancelled(){
		this.status = FileUploadStatus.CANCELLED
		this.setProgress(0)
	}

	setCompleted(){
		this.status = FileUploadStatus.COMPLETED
		this.setProgress(100)
	}

	setCompletedFinalizing(){
		this.status = FileUploadStatus.COMPLETED_FINALIZING
		this.setProgress(99)
	}

	setError(){
		this.status = FileUploadStatus.ERROR
		this.setProgress(0)
	}

	setInProgress(){
		this.status = FileUploadStatus.IN_PROGRESS
	}

	setPaused(){
		this.status = FileUploadStatus.PAUSED
	}

	setProgress(progress){
		this.progress = progress
	}

	cancelUpload(){
		if(this.uploadTask){
			this.uploadTask.cancel()
		}
		this.setCancelled()
	}
	pauseUpload(){
		this.uploadTask.pause()
		this.setPaused()
	}
	resumeUpload(){
		this.uploadTask.resume()
		this.setInProgress()
	}

}
