/**
 * Created by DemonRay on 2019/3/27.
 */

const defaultEdgeTypes = {}

const defaultEdgeStyles = [
	{
		selector: 'edge',
		style: {
			'curve-style': 'bezier',
			'target-arrow-shape': 'triangle',
			'arrow-scale': 0.001,
			'width': 2,
			'control-point-step-size': 50
		}
	},
	{
		'selector': 'edge[lineType]',
		'style': {
			'curve-style': 'data(lineType)',
			"taxi-direction": "auto",
			// "taxi-turn": 90
		}
	},
	{
		selector: 'edge[lineColor]',
		style: {
			'target-arrow-shape': 'triangle',
			'width': 2,
			'line-color': 'data(lineColor)',
			'source-arrow-color': 'data(lineColor)',
			'target-arrow-color': 'data(lineColor)',
			'mid-source-arrow-color': 'data(lineColor)',
			'mid-target-arrow-color': 'data(lineColor)'
		}
	},
	{
		selector: 'edge[lineColor]:active',
		style: {
			'overlay-color': '#0169D9',
			'overlay-padding': 3,
			'overlay-opacity': 0.25,
			'line-color': 'data(lineColor)',
			'source-arrow-color': 'data(lineColor)',
			'target-arrow-color': 'data(lineColor)',
			'mid-source-arrow-color': 'data(lineColor)',
			'mid-target-arrow-color': 'data(lineColor)'
		}
	},
	{
		selector: 'edge[lineColor]:selected',
		style: {
			'overlay-color': '#0169D9',
			'overlay-padding': 3,
			'overlay-opacity': 0.25,
			'line-color': 'data(lineColor)',
			'source-arrow-color': 'data(lineColor)',
			'target-arrow-color': 'data(lineColor)',
			'mid-source-arrow-color': 'data(lineColor)',
			'mid-target-arrow-color': 'data(lineColor)'
		}
	}
]

function getEdgeConf(type) {
	return defaultEdgeTypes.find(item => item.type === type)
}

export {defaultEdgeTypes, defaultEdgeStyles, getEdgeConf}
