/**
 * Created by DemonRay on 2019/3/24.
 */

import roundRectangle from '../assets/node-svgs/round-rectangle.svg'
import roundRectangleMagenta from '../assets/node-svgs/round-rectangle-deepblue.svg'
import triangle from '../assets/node-svgs/triangle.svg'
import triangleRed from '../assets/node-svgs/triangle-red.svg'
import ellipse from '../assets/node-svgs/ellipse.svg'
import ellipseGreen from '../assets/node-svgs/ellipseGreen.svg'

import hexagon from '../assets/node-svgs/hexagon.svg'
import star from '../assets/node-svgs/star.svg'
import pentagon from '../assets/node-svgs/pentagon.svg'
import diamond from '../assets/node-svgs/diamond.svg'
import diamondOrange from '../assets/node-svgs/diamond-orange.svg'
import tag from '../assets/node-svgs/tag.svg'
import polygon from '../assets/node-svgs/polygon.svg'

import femaleLesbian from '../assets/node-svgs/female-lesbian.svg'
import femaleTrans from '../assets/node-svgs/female-trans.svg'
import femaleBisexual from '../assets/node-svgs/female-bisexual.svg'

import templateKids from '../assets/node-svgs/template-kids.png'
import templateGen from '../assets/node-svgs/template-gen.png'

import maleGay from '../assets/node-svgs/male-gay.svg'
import maleTrans from '../assets/node-svgs/male-trans.svg'
import maleBisexual from '../assets/node-svgs/male-bisexual.svg'
import {PartTypeEnum} from "@/tools/IFSMappingParts/PartTypeEnum";

const nodeTypeToImage = {
	'male': roundRectangle,
	'female': ellipse,
	'rectangle': roundRectangle,
	'rectangleMagenta': roundRectangleMagenta,
	'triangle': triangle,
	'triangleRed': triangleRed,
	'ellipse': ellipse,
	'ellipseGreen': ellipseGreen,
	'hexagon': hexagon,
	'star': star,
	'pentagon': pentagon,
	'diamond': diamond,
	'diamondOrange': diamondOrange,
	'tag': tag,
	'polygon': polygon,
	'femaleTrans': femaleTrans,
	'femaleLesbian': femaleLesbian,
	'femaleBisexual': femaleBisexual,
	'maleTrans': maleTrans,
	'maleGay': maleGay,
	'maleBisexual': maleBisexual,
	'template-pair': templateKids,
	'template-generations': templateGen,


}

const shapeFromType = (type) => {
	type = type || ""
	if(type.startsWith("male") || type.startsWith("rectangle")){
		return 'rectangle'
	}
	if(type.startsWith("female") || type.startsWith("ellipse")){
		return 'ellipse'
	}

	switch (type) {
		case 'rectangle':
			return 'rectangle'
		case 'triangle':
			return 'triangle'
		case 'hexagon':
			return 'hexagon'
		case 'star':
			return 'star'
		case 'pentagon':
			return 'pentagon'
		case 'diamond':
			return 'diamond'
		case 'tag':
			return 'tag'
	}
	return 'ellipse'
}

function getPartnerGender(nodeType) {
	let binaryGender = typeof nodeType === 'string' ? nodeType : nodeType.binaryGender
	if(binaryGender === "male"){
		return "female"
	}
	return "male"
}

let opacityFromType = (type) => {
	if(nodeTypeToImage[type] != null) {
		return 0
	}
	return 1
}

const nodeTypesTemplates = [
	{
		type: 'template-pair',
		resize: false,
		category: 'Templates',
		label: "A couple + kids",
		buildIn: true,
		tags: []
	},
	{
		type: 'template-generations',
		resize: false,
		category: 'Templates',
		label: "Multi generations",
		buildIn: true,
		tags: []
	},
]

function getNodeTypesSymbolsOptionsFn(nodeTypesSymbols, filterFn = () => true) {
	let nodeTypeOptions = []
	nodeTypesSymbols.forEach(nodeType => {
		if(filterFn(nodeType)) {
			nodeTypeOptions.push({label: nodeType.label.replaceAll("<br/>", " "), value: nodeType.type})
		}
	})
	return nodeTypeOptions
}

function getNodeTypesSymbolsOptions(filterFn) {
	return getNodeTypesSymbolsOptionsFn(nodeTypesSymbols, filterFn)
}
function getIFSNodeTypesSymbolsOptions(filterFn) {
	return getNodeTypesSymbolsOptionsFn(ifsPartsNodeTypes, filterFn)
}

const nodeTypesSymbols = [
	{
		type: 'female',
		resize: false,
		category: 'Symbols',
		label: "Female",
		binaryGender: "female",
		buildIn: true,
		tags: []
	},
	{
		type: 'male',
		shape: 'rectangle',
		resize: false,
		category: 'Symbols',
		label: "Male",
		binaryGender: "male",
		buildIn: true,
		tags: []
	},
	{
		type: 'femaleLesbian',
		resize: false,
		category: 'Symbols',
		label: "Lesbian",
		binaryGender: "female",
		buildIn: true,
		tags: []
	},
	{
		type: 'maleGay',
		resize: false,
		category: 'Symbols',
		label: "Gay",
		buildIn: true,
		binaryGender: "male",
		tags: []
	},
	{
		type: 'femaleTrans',
		resize: false,
		category: 'Symbols',
		label: "Female<br/>Trans",
		buildIn: true,
		binaryGender: "female",
		tags: []
	},
	{
		type: 'maleTrans',
		resize: false,
		category: 'Symbols',
		label: "Male<br/>Trans",
		buildIn: true,
		binaryGender: "male",
		tags: []
	},
	{
		type: 'femaleBisexual',
		resize: false,
		category: 'Symbols',
		label: "Female Bisexual",
		buildIn: true,
		binaryGender: "female",
		tags: []
	},
	{
		type: 'maleBisexual',
		resize: false,
		category: 'Symbols',
		label: "Male Bisexual",
		buildIn: true,
		tags: []
	},

	{
		type: 'triangle',
		shape: 'triangle',
		resize: false,
		label: 'Pregnancy',
		category: 'Symbols',
		buildIn: true
	},
	{
		type: 'diamond',
		resize: false,
		width: 86,
		height: 78,
		category: 'Symbols',
		label: "Pet",
		buildIn: true
	},

	// {
	// 	type: 'round-rectangle',
	// 	resize: true,
	// 	width: 186,
	// 	height: 78,
	// 	category: 'Extra',
	// 	label: "Text label",
	// 	buildIn: true
	// },

	// {
	//   type: 'diamond',
	//   src: diamond,
	//   bg: '#5CDBD3',
	//   resize: false,
	//   width: 76,
	//   height: 76,
	//   category: '基础形状',
	//   buildIn: true
	// },
	// {
	//   type: 'pentagon',
	//   src: pentagon,
	//   bg: '#722ed1',
	//   resize: false,
	//   width: 76,
	//   height: 76,
	//   category: '基础形状',
	//   buildIn: true
	// },
	// {
	//   type: 'tag',
	//   src: tag,
	//   bg: '#efbae4',
	//   resize: false,
	//   width: 70,
	//   height: 76,
	//   category: '基础形状',
	//   buildIn: true
	// },
	// {
	//   type: 'star',
	//   src: star,
	//   bg: '#00e217',
	//   resize: false,
	//   width: 76,
	//   height: 76,
	//   category: '基础形状',
	//   buildIn: true
	// },
	// {
	//   type: 'hexagon',
	//   src: hexagon,
	//   bg: '#ea9f00',
	//   resize: false,
	//   width: 76,
	//   height: 70,
	//   category: '基础形状',
	//   buildIn: true
	// },
	// {
	//   'type': 'polygon',
	//   'src': polygon,
	//   bg: '#f7130e',
	//   resize: false,
	//   width: 76,
	//   height: 76,
	//   'points': [
	//     -0.33, -1,
	//     0.33, -1,
	//     0.33, -0.33,
	//     1, -0.33,
	//     1, 0.33,
	//     0.33, 0.33,
	//     0.33, 1,
	//     -0.33, 1,
	//     -0.33, 0.33,
	//     -1, 0.33,
	//     -1, -0.33,
	//     -0.33, -0.33
	//   ],
	//   category: '基础形状',
	//   buildIn: true
	// }
]

const defaultNodeTypes = [
	...nodeTypesTemplates,
	...nodeTypesSymbols,
]
const ifsPartsNodeTypes = [

	{
		type: 'ellipse',
		resize: false,
		// width: 76,
		// height: 76,
		category: 'Roles',
		label: "Unknown",
		buildIn: true,
		tags: [],
		data: {
			partType: PartTypeEnum.UNKNOWN,

		},
	},
	{
		type: 'rectangleMagenta',
		resize: false,
		// width: 76,
		// height: 76,
		category: 'Roles',
		label: "Manager",
		buildIn: true,
		tags: [],
		data: {
			partType: PartTypeEnum.PROTECTOR,
		},
	},
	{
		type: 'triangle',
		resize: false,
		// width: 76,
		// height: 76,
		label: 'Firefighter',
		category: 'Roles',
		buildIn: true,
		data: {
			partType: PartTypeEnum.PROTECTOR,
		},

	},
	{
		type: 'diamond',
		resize: false,
		// width: 76,
		// height: 76,
		category: 'Roles',
		label: "Exile",
		buildIn: true,
		data: {
			partType: PartTypeEnum.EXILE,
		},

	},
	{
		type: 'coreSelf',
		shape: 'polygon',
		name: "Self",
		label: "Self",
		resize: false,
		bg: "#0EEA2D",
		border: true,
		opacity: 1,
		width: 176,
		height: 176,
		category: 'Roles',
		buildIn: true,
		tags: [],
		data: {
			isCoreSelf: true,
			isExcluded: false,
			instanceLimit: 1,
			size: 2,
			nonDeletable: false,
			showNotes: true,
		}
	},

]

// Utility function to filter out null and undefined values from an object
const filterValidStyles = (styleObj) =>{
	Object.keys(styleObj).forEach(key => {
		if(styleObj[key] == null){
			delete styleObj[key]
		}
	})
	return styleObj

}

// Function to clean the entire array by applying `filterValidStyles` to each style object
const cleanStyles = (stylesArray) =>{
	for(let i = 0; i < stylesArray.length; i++){
		stylesArray[i].style = filterValidStyles(stylesArray[i].style)
	}
	return stylesArray
}

const compoundDnDStyles = cleanStyles([
	{
		selector: '.cdnd-new-parent',
		style: {
			'border-color': 'red',
			'background-color': 'gray',
			'background-opacity': 0.3,
		}

	},
	{
		selector: '.cdnd-grabbed-node',
		style: {
			'bg': 'red'
		}
	},

	{
		selector: '.cdnd-drop-sibling',
		style: {
			'bg': 'red'
		}
	},

	{
		selector: '.cdnd-drop-target',
		style: {
			'border-color': 'red',
			'border-style': 'dashed'
		}
	}
	])

const defaultNodeStyles = cleanStyles([

	{
		selector: 'node',
		style: {
			'background-opacity': (e) => e.data('bg-opacity') || e.data('opacity') || opacityFromType(e.data('type')),
			'background-color': (e) => e.data('bg') || 'transparent',
			// 'background-image-opacity': (e) => e.active() || e.selected() || e.connectedEdges().some(edge => edge.selected()) ? 1 : 0.2,
			'shape': (e) => e.data('shape') || shapeFromType(e.data('type')),
			'height': (e) => e.data('height') || 76,
			'width': (e) => e.data('width') || 76,
			'text-valign': 'center',
			'text-halign': 'center',
			// 'background-fit': 'contain',
			'background-image': (e) => nodeTypeToImage[e.data('type')] || 'none',


			'border-width': (e) => e.data("border") ? 1 : 0,
			'border-opacity': (e) => e.data("border") ? 0.8 : 0,
			'border-color': (e) => e.data("border") ? e.data('bg') : 'transparent',
		}
	},
	// {
	// 	'selector': 'node[type]',
	// 	'style': {
	// 		'shape': 'data(type)',
	// 		'height': 'data(height)',
	// 		'width': 'data(width)',
	// 		'text-valign': 'center',
	// 		'text-halign': 'center'
	// 	}
	// },
	{
		'selector': 'node[points]',
		'style': {
			'shape-polygon-points': 'data(points)',
			'text-wrap': 'wrap'
		}
	}, {
		'selector': 'node[label]',
		'style': {
			'label': 'data(label)',
			'text-valign': 'bottom',
			'text-halign': 'center',
			'text-margin-y': 10,
			'text-background-color': "#ffffff",
			'text-background-opacity': 0.8,
			'text-background-padding': 5,
			'font-size': 30,
			"text-wrap": "wrap",
			'min-zoomed-font-size': 8
		}
	},
	// {
	// 	'selector': 'node[image]',
	// 	'style': {
	// 		'background-opacity': 0,
	// 		'background-fit': 'cover',
	// 		'background-image': 'data(src)'
	// 	}
	// },
	// {
	// 	'selector': 'node[bg]',
	// 	'style': {
	// 		'background-opacity': 0.45,
	// 		'background-color': 'data(bg)',
	// 		'border-width': 1,
	// 		'border-opacity': 0.8,
	// 		'border-color': 'data(bg)'
	// 	}
	// },
	{
		selector: 'node:active',
		style: {
			'overlay-color': '#0169D9',
			'overlay-padding': 12,
			'overlay-opacity': 0.25
		}
	}, {
		selector: 'node:selected',
		style: {
			'overlay-color': '#0169D9',
			'overlay-padding': 12,
			'overlay-opacity': 0.25
		}
	},
	{
		selector: 'node[type="coreSelf"]', // Select nodes where data.type = "coreSelf"
		style: {
			'shape': 'polygon',
			'shape-polygon-points': '0 1, 1 -1, -1 -1', // Upside-down triangle
			'bg-opacity': 0.3,
			'width': 500,
			'height': 500,

		}
	},
	...compoundDnDStyles,
	])

export {
	ifsPartsNodeTypes,
	defaultNodeTypes,
	defaultNodeStyles,
	nodeTypeToImage,
	nodeTypesSymbols,
	nodeTypesTemplates,
	getPartnerGender,
	getNodeTypesSymbolsOptions,
	getIFSNodeTypesSymbolsOptions,
}
