import ProgramStep from "@shared/Program/ProgramStep/ProgramStep";
import {OwnerEnum, SessionConfirmationStatus, StepType} from "@shared/Program/ProgramStep/helpers/ProgramStepEnums";
import RecurrenceItem from "@shared/Recurrence/RecurrenceItem";
import {Type} from "class-transformer";
import dayjs from "dayjs";
import {LocationItem} from "@shared/scheduler/LocationItem";
import {TimeSource} from "@shared/scheduler/TimeSource";



export default class CoachingSessionStep extends ProgramStep{

	stepType: string = StepType.CALL

	durationMinutes : number = 0;
	@Type(() => LocationItem)
	locationItem : LocationItem = null
	suggestedDateTimeTs : number[] = [];
	suggestedTimeOwnerUid : string = "";
	confirmedTime : number = 0;
	confirmationStatus: SessionConfirmationStatus = null
	timeSource: string = TimeSource.SCHEDULER;


	// timeOptions: SessionTimeOptionsEnum = SessionTimeOptionsEnum.SPECIFIC_TIME
	recurMasterId: string = null
	isSplitEventPast: boolean = null
	isRecurring: boolean = false

	@Type(() => RecurrenceItem)
	recurrence: RecurrenceItem = null

	// @Type(() => EmailReminderItem)
	// emailReminder: EmailReminderItem = null

	sessionTypeId: string = null
	sessionItemId: string = null

	constructor(name = "Coaching session") {
		super(name);
	}

	reset(){
		this.suggestedDateTimeTs = []
		// this.suggestedTimeOwnerUid = "";//need to keep it as is
		this.confirmedTime = 0;
		super.reset()
	}

	isCallCompleted(): boolean{
		return this.isCallConfirmed() && this.confirmedTime + this.durationMinutes * 60 * 1000 < new Date().getTime();
	}

	isCallConfirmed(): boolean{
		return this.confirmedTime > 0 || this.isSessionConfirmed();
	}
	suggestedTimeOwner(currentUserId: string): string{
		let owner = this.suggestedTimeOwnerUid.length === 0 || this.suggestedTimeOwnerUid === currentUserId ? OwnerEnum.ME : OwnerEnum.OTHER;
		return owner
	}
	doWeNeedToConfirm(currentUserId: string): boolean{
		let owner = this.suggestedTimeOwner(currentUserId);
		return owner !== OwnerEnum.ME;
	}
	needsConfirmation(email): boolean{
		return this.confirmedTime <= 0;// || this.participantConfirmationPending(email);
	}

	confirmedTimeFormatted(format: ?string): string{
		if(!format){
			format = "hh:mma on ddd, MMMM DD";
		}
		if(this.confirmedTime > 0){
			return dayjs(this.confirmedTime).format(format)
		}
		return ''
	}

	getConfirmedTimeFormattedDate(){
		return dayjs(this.confirmedTime).format("L")
	}

	syncFromSessionItem(sessionItem){
		let res = false
		if(sessionItem){
			if(this.sessionTypeId !== sessionItem.eventTypeId){
				this.sessionTypeId = sessionItem.eventTypeId
				res = true
			}
			console.log("syncFromSessionItem: sessionItem.id = ", sessionItem.id)
			if(this.sessionItemId !== sessionItem.id){
				this.sessionItemId = sessionItem.id
				res = true
			}

			if(this.locationItem !== sessionItem.location){
				this.locationItem = sessionItem.location
				res = true
			}

			if(this.isRecurring !== sessionItem.isRecurring()){
				this.isRecurring = sessionItem.isRecurring()
				res = true
			}

			if(this.durationMinutes !== sessionItem.durationMinutes()){
				this.durationMinutes = sessionItem.durationMinutes()
				res = true
			}

			let newStatus = sessionItem.confirmationStatus || SessionConfirmationStatus.CONFIRMED
			console.log("syncFromSessionItem: newStatus = ", newStatus)
			console.log("syncFromSessionItem: this.confirmedTime = ", this.confirmedTime, ", sessionItem.startTime = ", sessionItem.startTime)
			if(this.confirmationStatus !== newStatus){
				this.confirmationStatus = newStatus
				res = true
			}
			if(this.confirmedTime !== sessionItem.startTime && !this.isSessionCancelled()) {
				this.confirmedTime = sessionItem.startTime
				res = true
			}
		} else {
			if(this.confirmationStatus === SessionConfirmationStatus.CONFIRMED){
				this.confirmationStatus = SessionConfirmationStatus.CANCELLED
				res = true
			}
			if(!this.isSessionCancelled()){
				this.confirmedTime = 0;
				res = true
			}
		}

		return res

	}

	isSessionConfirmed(){
		return this.confirmationStatus === SessionConfirmationStatus.CONFIRMED
	}

	isSessionCancelled(){
		return this.confirmationStatus === SessionConfirmationStatus.CANCELLED
	}

	isSessionConfirmPending(){
		return !this.isSessionConfirmed() && !this.isSessionCancelled() && !this.isInPast()
		// return this.confirmationStatus === SessionConfirmationStatus.PENDING_CLIENT
		// 	|| this.confirmationStatus === SessionConfirmationStatus.PENDING_COACH
	}

	isInPast(){
		return this.confirmedTime !== 0 && this.confirmedTime < new Date().getTime()
	}

	isSessionConfirmPendingClient(){
		return this.confirmationStatus === SessionConfirmationStatus.PENDING_CLIENT
	}

	isSessionConfirmPendingCoach(){
		return this.confirmationStatus === SessionConfirmationStatus.PENDING_COACH
	}


	equalableProps() {
		return [
			"locationItem",
			"durationMinutes",
			"confirmedTime",
			"recurrence",
			"isRecurring",
			"confirmationStatus"
		]
	}


}



