<template>
	<span class="qw-tooltip-root" v-bind="attrsStylesClass">
		<div v-if="!hasText && forceShow" class="display-inline-block">
            <slot :name="slotTriggerNameComputed">
                <slot name="icon-prefix"/>
                <span :class="classes"></span>
            </slot>
		</div>
		<el-tooltip v-else-if="hasText"
					v-bind="attrsNoStylesClass"
					ref="tooltip"
					class="item"
					:enterable="enterable"
					effect="dark"
					:placement="position"
					style="display: inline-block;">
			<div>
				<slot :name="slotTriggerNameComputed" v-if="!hasSlot('trigger')">
					<slot name="icon-prefix"/>
					<span :class="classes"></span>
				</slot>
			</div>
			<template #content>
				<div :style="{'max-width': maxWidth}">
					<slot :name="slotContentNameComputed">
						<div v-html="textComputed" class="white-space-pre"></div>
					</slot>
				</div>
			</template>
		</el-tooltip>
	</span>

</template>

<script>
import {getSlotChildrenText, isFunction} from "@/utils/helpers.js";
import BaseComponent from "@/core/components/BaseComponent.vue";

export default {
	name: "ToolTipView",
	extends: BaseComponent,
	props: {
		analyticsProps: Object,
		text: String,
		forceShow: Boolean,
		enterable: Boolean,
		position: {String, default: "right"},
		type: {String, default: "info"},
		useDefaultSlotAsTrigger: Boolean,
		maxWidth: {String, default: "15rem"}
	},

	inheritAttrs: false,

	watch: {
		analyticsProps: {
			deep: true,
			handler() {
				this.setupAnalyticsTracking()
			}
		}
	},

	data() {
		return {
			hoverTracker: null,
			slotTriggerName: "icon",
			slotContentName: "default",
		}
	},

	mounted() {
		this.setupAnalyticsTracking()
	},

	methods: {
		hasSlot(name = 'default') {
			let slot = this.$slots[name]
			if(isFunction(slot)){
				slot = slot()
			}
			console.log("hasSlot: slot = ", slot, ", name = ", name, ", this.$slots = ", this.$slots)
			let hasText = (slot && slot.length) ? getSlotChildrenText(slot) : false
			return hasText
		},

		async setupAnalyticsTracking() {
			if (this.analyticsProps && this.$refs.tooltip) {
				let tooltipEl = this.$refs.tooltip.$el
				let eventName = "Tooltip hover"
				let props = this.analyticsProps || {}
				let hoverTracker = await this.$analyticManager.trackHoverEvent(tooltipEl, eventName, props)
				if (this.hoverTracker) {
					this.hoverTracker.stop()
				}
				this.hoverTracker = hoverTracker

			}
		}
	},
	computed: {
		textComputed() {
			return this.text || getSlotChildrenText(this.$slots.default && this.$slots.default())
		},

		hasText() {
			return !!this.text || this.hasSlot()
		},

		classes() {
			return [this.typeClass, this.typeIcon]
		},

		typeClass() {
			switch (this.type) {
				case "warning":
					return "text-warning"
				case "danger":
					return "text-danger"
			}
			return ""
		},

		typeIcon() {
			switch (this.type) {
				case "warning":
				case "danger":
					return "fontello icon-warning-empty"
			}
			return "infolink"
		},

		slotTriggerNameComputed() {
			return this.useDefaultSlotAsTrigger ? this.slotContentName : this.slotTriggerName
		},

		slotContentNameComputed() {
			return this.useDefaultSlotAsTrigger ? this.slotTriggerName : this.slotContentName
		}
	}
}
</script>

<style lang="scss">

.qw-tooltip-root {
	display: inline-block;
	.el-tooltip__trigger {
		display: inline-block;
	}
}

</style>
